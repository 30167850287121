const ReleaseStatus = {
  CREATED: {
    key: "CREATED",
    description: "RELEASESTATUS_CREATED",
    name: "RELEASESTATUS_CREATED",
    nextAction: "NEW_RELEASESTATUS_CREATED_NEXTACTION",
    historyLabel: "RELEASESTATUS_CREATED_HISTORYLABEL",
    nextActionTitle: "NEW_RELEASESTATUS_CREATED_NEXTACTIONTITLE",
    stepName: "RELEASESTATUS_CREATED_STEPNAME",
    stepKey: "CREATED",
    activeStep: 1,
    infoLabel: "NEW_RELEASESTATUS_CREATED_INFO_LABEL",
    infoLabelEmployee: {
      textUnassigned: "RELEASESTATUS_CREATED_EMPL_INFO_LABEL",
      assignOption: false,
    },
    activeTabSection: "FUNCTIONAL_TESTING",
  },
  FUNCTIONAL_TESTING_SUCCESS: {
    key: "FUNCTIONAL_TESTING_SUCCESS",
    description: "RELEASESTATUS_FUNCTIONAL_TESTING_SUCCESS",
    name: "SUCCESS_TEXT",
    nextAction: "RELEASESTATUS_FUNCTIONAL_TESTING_SUCCESS_NEXTACTION",
    historyLabel: "RELEASESTATUS_FUNCTIONAL_TESTING_HISTORYLABEL",
    nextActionTitle: "RELEASESTATUS_FUNCTIONAL_TESTING_NEXTACTIONTITLE",
    stepName: "RELEASESTATUS_FUNCTIONAL_TESTING_STEPNAME",
    stepKey: "FUNCTIONAL_TESTING",
    activeStep: 2,
    infoLabel: "RELEASESTATUS_FUNCTIONAL_TESTING_SUCCESS_INFO_LABEL",
    infoLabelEmployee: {
      dialogInfo: {
        key: "ASSIGN_TO",
        titleUnassigned: "ASSIGN_SECURITYSCAN_TITLE",
        titleAssigned: "ASSIGNED_SECURITYSCAN_TITLE",
        subtitle: "ASSIGN_TO_ME_SECURITYSCAN_SUBTITLE",
        assignedText: "ASSIGNED_TO_MESSAGE_DIALOG_SECURITYSCAN",
      },
      textUnassigned: "RELEASESTATUS_SECURITYSCAN_UNASSIGNED_INFO_LABEL",
      textAssigned: "RELEASESTATUS_SECURITYSCAN_ASSIGNED_INFO_LABEL",
      assignOption: true,
      assignedText: "SECURITYSCAN_ASSIGNED_TO_MESSAGE",
      checkboxText: "ASSIGN_SECURITYSCAN_TEXT",
    },
    activeTabSection: "SECURITYSCAN",
    nextStatus: "SECURITYSCAN_SUCCESS",
    reportName: "FUNCTIONAL_TESTING_REPORT",
  },
  FUNCTIONAL_TESTING_SKIPPED: {
    key: "FUNCTIONAL_TESTING_SKIPPED",
    description: "RELEASESTATUS_FUNCTIONAL_TESTING_SKIPPED",
    name: "SKIPPED_TEXT",
    nextAction: "RELEASESTATUS_FUNCTIONAL_TESTING_SUCCESS_NEXTACTION",
    historyLabel: "RELEASESTATUS_FUNCTIONAL_TESTING_HISTORYLABEL",
    nextActionTitle: "RELEASESTATUS_FUNCTIONAL_TESTING_NEXTACTIONTITLE",
    stepName: "RELEASESTATUS_FUNCTIONAL_TESTING_STEPNAME",
    stepKey: "FUNCTIONAL_TESTING",
    activeStep: 2,
    infoLabel: "RELEASESTATUS_FUNCTIONAL_TESTING_SKIPPED_INFO_LABEL",
    infoLabelEmployee: {
      dialogInfo: {
        key: "ASSIGN_TO",
        titleUnassigned: "ASSIGN_SECURITYSCAN_TITLE",
        titleAssigned: "ASSIGNED_SECURITYSCAN_TITLE",
        subtitle: "ASSIGN_TO_ME_SECURITYSCAN_SUBTITLE",
        assignedText: "ASSIGNED_TO_MESSAGE_DIALOG_SECURITYSCAN",
      },
      textUnassigned: "RELEASESTATUS_SECURITYSCAN_UNASSIGNED_INFO_LABEL",
      textAssigned: "RELEASESTATUS_SECURITYSCAN_ASSIGNED_INFO_LABEL",
      assignOption: true,
      assignedText: "SECURITYSCAN_ASSIGNED_TO_MESSAGE",
      checkboxText: "ASSIGN_SECURITYSCAN_TEXT",
    },
    activeTabSection: "SECURITYSCAN",
    nextStatus: "SECURITYSCAN_SUCCESS",
    reportName: "FUNCTIONAL_TESTING_REPORT",
  },
  FUNCTIONAL_TESTING_FAILED: {
    key: "FUNCTIONAL_TESTING_FAILED",
    description: "RELEASESTATUS_FUNCTIONAL_TESTING_FAILED",
    name: "FAILED_TEXT",
    historyLabel: "RELEASESTATUS_FUNCTIONAL_TESTING_HISTORYLABEL",
    stepName: "RELEASESTATUS_FUNCTIONAL_TESTING_STEPNAME",
    stepKey: "FUNCTIONAL_TESTING",
    activeStep: 1,
    infoLabel: "RELEASESTATUS_FUNCTIONAL_TESTING_FAILED_INFO_LABEL",
    activeTabSection: "FUNCTIONAL_TESTING",
    reportName: "FUNCTIONAL_TESTING_REPORT",
  },
  SECURITYSCAN_SUCCESS: {
    key: "SECURITYSCAN_SUCCESS",
    description: "SECURITYSCAN_SUCCESS_LABEL",
    name: "SUCCESS_TEXT",
    nextAction: "RELEASESTATUS_SECURITYSCAN_SUCCESS_NEXTACTION",
    historyLabel: "RELEASESTATUS_SECURITYSCAN_HISTORYLABEL",
    nextActionTitle: "RELEASESTATUS_SIGNED_STEPNAME",
    stepName: "RELEASESTATUS_SECURITYSCAN_STEPNAME",
    stepKey: "SECURITYSCAN",
    activeStep: 3,
    infoLabel: "RELEASESTATUS_SECURITYSCAN_SUCCESS_INFO_LABEL",
    infoLabelEmployee: {
      dialogInfo: {
        key: "ASSIGN_TO",
        titleUnassigned: "ASSIGN_SIGNING_TITLE",
        titleAssigned: "ASSIGN_SIGNING_TITLE",
        subtitle: "ASSIGN_TO_ME_SIGNED_SUBTITLE",
        assignedText: "ASSIGNED_TO_MESSAGE_DIALOG_SIGNED",
      },
      textUnassigned: "RELEASESTATUS_SIGNED_UNASSIGNED_INFO_LABEL",
      textAssigned: "RELEASESTATUS_SIGNED_ASSIGNED_INFO_LABEL",
      assignOption: false,
      assignedText: "SIGNING_ASSIGNED_TO_MESSAGE",
      checkboxText: "ASSIGN_TO_ME_SIGNED_TEXT",
    },
    activeTabSection: "SIGNING",
    nextStatus: "SIGNED",
    reportName: "SECURITY_REPORT",
  },
  SECURITYSCAN_FAILED: {
    key: "SECURITYSCAN_FAILED",
    description: "SECURITYSCAN_FAILED_LABEL",
    name: "FAILED_TEXT",
    historyLabel: "RELEASESTATUS_SECURITYSCAN_HISTORYLABEL",
    stepName: "RELEASESTATUS_SECURITYSCAN_STEPNAME",
    stepKey: "SECURITYSCAN",
    activeStep: 2,
    infoLabel: "RELEASESTATUS_SECURITYSCAN_FAILED_INFO_LABEL",
    activeTabSection: "SECURITYSCAN",
    reportName: "SECURITY_REPORT",
  },
  SIGNING_SUCCESS: {
    key: "SIGNING_SUCCESS",
    description: "RELEASESTATUS_SIGNING_SUCCESS",
    name: "SUCCESS_TEXT",
    nextAction: "RELEASESTATUS_SIGNED_NEXTACTION",
    historyLabel: "RELEASESTATUS_SIGNED_HISTORYLABEL",
    nextActionTitle: "DIALOG_UPLOADEDTITLE",
    stepName: "RELEASESTATUS_SIGNED_STEPNAME",
    stepKey: "SIGNING",
    activeStep: 4,
    activeTabSection: "APPLICATION_INFO",
    infoLabel: "RELEASESTATUS_SIGNING_SUCCESS_INFO_LABEL",
    infoLabelEmployee: {
      textUnassigned: "RELEASESTATUS_UPLOAD_TO_STORE_UNASSIGNED_INFO_LABEL",
      assignOption: false,
    },
    nextStatus: "AVAILABLE_IN_STORE",
  },
  SIGNING_FAILED: {
    key: "SIGNING_FAILED",
    description: "RELEASESTATUS_SIGNING_FAILED",
    name: "FAILED_TEXT",
    historyLabel: "RELEASESTATUS_SIGNED_HISTORYLABEL",
    stepName: "RELEASESTATUS_SIGNED_STEPNAME",
    stepKey: "SIGNING",
    activeStep: 3,
    activeTabSection: "SIGNING",
    infoLabel: "RELEASESTATUS_SIGNING_FAILED_INFO_LABEL",
    infoLabelEmployee: {
      textUnassigned: "RELEASESTATUS_SIGNING_FAILED_UNASSIGNED_INFO_LABEL",
      assignOption: false,
    },
    reportName: "SIGNING_REPORT_LABEL",
  },
  AVAILABLE_IN_STORE: {
    key: "AVAILABLE_IN_STORE",
    description: "RELEASESTATUS_AVAILABLE_IN_STORE",
    name: "RELEASESTATUS_AVAILABLE_IN_STORE",
    historyLabel: "RELEASESTATUS_AVAILABLE_IN_STORE_HISTORYLABEL",
    stepName: "RELEASESTATUS_AVAILABLE_IN_STORE_STEPNAME",
    stepKey: "UPLOAD_TO_STORE",
    activeStep: 5,
    infoLabel: "RELEASESTATUS_AVAILABLE_IN_STORE_INFO_LABEL",
    activeTabSection: "GENERAL_INFO",
  },
};

export default ReleaseStatus;
