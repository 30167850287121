import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React, { useState } from "react";
import SpinnerService from "../../../services/SpinnerService";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

function DocumentationSection({
  sectionInfo,
  sectionHeading,
  translate,
  projectService,
}) {
  function downloadFromSharepoint(filename) {
    SpinnerService.defaultSpinner(
      projectService
        .getDocumentationDownloadLink(filename)
        .then((response) => window.location.assign(response.data)),
      translate,
      "TOAST_DOWNLOADING",
      "TOAST_DOWNLOAD_SUCCEEDED",
      null
    );
  }

  return (
    <Accordion className="accordion-wrapper documentation-accordion">
      <AccordionSummary
        expandIcon={
          <span data-testid={sectionHeading + "_dropdown"}>
            <CcvMaterialIcon iconName="expand_more" size="large" />
          </span>
        }
        disableTouchRipple={true}
      >
        <a
          href={sectionInfo.type === "link" && sectionInfo.path}
          onClick={() =>
            sectionInfo.type === "file" &&
            downloadFromSharepoint(sectionHeading)
          }
          target="_blank"
        >
          <CcvText size="medium" testId={sectionHeading}>
            <CcvMaterialIcon
              iconName={
                sectionInfo.type === "file" ? "file_download" : "description"
              }
              size="normal"
              iconStyle="outlined"
            />
            {sectionHeading}
          </CcvText>
        </a>
      </AccordionSummary>
      <AccordionDetails>
        {sectionInfo.description.length > 0 ? (
          sectionInfo.description.map((text) => (
            <CcvText
              color="medium-grey"
              size="medium"
              testId={text?.toLowerCase()}
            >
              {translate(text)}
            </CcvText>
          ))
        ) : (
          <CcvText
            color="medium-grey"
            size="medium"
            testId="no_description_available"
          >
            {translate("NO_DESCRIPTION_AVAILABLE")}
          </CcvText>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default DocumentationSection;
