import React, { useEffect } from "react";
import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import Textarea from "../form/Textarea";
import { useTranslation } from "react-i18next";
import ValidationError from "../form/ValidationError";
import FormatService from "../../services/FormatService";
import { ShowForRole } from "../ShowForRole";
import FormValidation from "../../domain/FormValidation";
import ResultLabel from "./ResultLabel";

function PermissionInput({
  permission,
  clearErrors,
  errors,
  setValue,
  disabled,
  textAreaClassName,
}) {
  const [t] = useTranslation();

  function getStatusLabels(permission) {
    const statusLabels = [];

    if (permission?.status === "NEW" || permission?.status === "UPDATED") {
      statusLabels.push({
        text: t(permission.status),
        type: "ongoing",
      });
    }

    statusLabels.push({
      text: permission?.type,
      type: permission?.type === "DANGEROUS" ? "failed" : "inactive",
    });

    return statusLabels;
  }

  return (
    <>
      <CcvText textStyle="semi-bold" testId={permission.name + "_header"}>
        {permission?.name}
      </CcvText>
      <Textarea
        className={textAreaClassName}
        classNameBlock="no-spacing"
        translate={t}
        labelText="REASON_LABEL"
        statusLabels={getStatusLabels(permission)}
        showForRole="Employee"
        field={FormatService.getPermissionValidationKey(permission.name)}
        defaultInputData={permission?.reason}
        setValue={setValue}
        required={true}
        disabled={disabled}
        clearErrors={clearErrors}
        shouldValidateOnInput={false}
        maxLength={FormValidation.MAX_REASON_INPUT_LENGTH}
        validationError={
          errors && (
            <ValidationError
              error={
                errors[
                  FormatService.getPermissionValidationKey(permission.name)
                ]
              }
              errorText={t("PERMISSION_ADD_REASON")}
              testId="permission_reason_error"
            />
          )
        }
      />
    </>
  );
}

export default PermissionInput;
