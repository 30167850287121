import { CcvHeading, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EmptyIconBlock from "../../../../components/emptyBlocks/EmptyIconBlock";
import RowTable from "../../../../components/tables/rowTable/RowTable";
import PaymentEngine from "../../../../domain/PaymentEngine";
import Textarea from "../../../../components/form/Textarea";
import InfoDialog from "../../../../components/dialogs/InfoDialog";
import { ShowForRole } from "../../../../components/ShowForRole";
import FormatService from "../../../../services/FormatService";
import ReleaseStatus from "../../../../domain/ReleaseStatus";
import DownloadTypes from "../../../../domain/DownloadType";

function FunctionalTestingInfoComponent({
  release,
  releaseHistory,
  downloadFromSharepoint,
  paymentEngineVersions,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogInfo, setDialogInfo] = useState();
  let functionalTestingRevision = getHistoryInfo([
    "FUNCTIONAL_TESTING_SKIPPED",
    "FUNCTIONAL_TESTING_FAILED",
    "FUNCTIONAL_TESTING_SUCCESS",
  ]);

  function getHistoryInfo(filter) {
    return releaseHistory
      ?.filter((revision) => filter.includes(revision.status))
      .reverse()[0];
  }

  function isStatusInHistory(status) {
    return releaseHistory.some((revision) => revision.status?.includes(status));
  }

  function getHistoryRowBody(rowInfo, emptyMessage, user, children) {
    if (rowInfo) {
      return (
        <div className="multi-side-block row-table-security-scan">
          <div>
            <ShowForRole permission="Employee">
              <span className="no-wrap">
                <CcvText
                  size="medium"
                  color="dark-grey"
                  testId="securityscan_user"
                >
                  {user ? user : rowInfo.user}
                </CcvText>
              </span>
            </ShowForRole>
            <CcvText
              size="medium"
              color="dark-grey"
              testId={rowInfo.status?.toLowerCase() + "_info"}
            >
              {rowInfo.creationDate &&
                FormatService.getTimeFormat(rowInfo.creationDate) +
                  " - " +
                  FormatService.getMediumDateFormat(rowInfo.creationDate)}
            </CcvText>
          </div>
          {children && children}
        </div>
      );
    } else {
      return (
        <CcvText size="medium" color="grey" testId={emptyMessage.toLowerCase()}>
          {t(emptyMessage)}
        </CcvText>
      );
    }
  }

  function getFeedbackDialog(dialogRevisionInfo, buttonText) {
    return (
      <>
        <CcvText size="medium" color="medium-grey">
          <span
            data-testid="show_feedback_button"
            className="download-link no-wrap"
            onClick={() => {
              setDialogInfo(dialogRevisionInfo);
              setOpenDialog(true);
            }}
          >
            {t(buttonText ? buttonText : "SHOW_FEEDBACK")}
          </span>
        </CcvText>
        <InfoDialog
          open={openDialog}
          onDisagree={() => setOpenDialog(false)}
          dialogTitle={dialogInfo && ReleaseStatus[dialogInfo.status].stepName}
          dialogSubtitle="DIALOG_FEEDBACK"
          dialogInfo={
            dialogInfo && {
              releaseId: dialogInfo.id,
              releaseStatus: dialogInfo.status,
              signing: dialogInfo.signing,
              functionalTest: dialogInfo.functionalTest,
              securityscan: dialogInfo.securityscan,
              date: FormatService.getShortDateFormat(dialogInfo.creationDate),
              time: FormatService.getTimeFormat(dialogInfo.creationDate),
              ...dialogInfo,
            }
          }
        />
      </>
    );
  }

  return (
    <>
      <span className="spacing-bottom">
        <CcvHeading
          text={t("FUNCTIONAL_TESTING_HEADING")}
          size="small"
          testId="functional_testing_title"
        />
      </span>
      {release ? (
        <div className="multi-side-block gap">
          <div className="left">
            <RowTable
              translate={t}
              data={[
                {
                  columnName: "RELEASE_APK_LABEL",
                  data: "DOWNLOAD_UNSIGNED_LABEL",
                  function: () =>
                    downloadFromSharepoint(DownloadTypes.UNSIGNED_APK),
                  type: "download",
                },
                {
                  columnName: "RELEASESTATUS_FUNCTIONAL_TESTING_STEPNAME",
                  data:
                    releaseHistory &&
                    getHistoryRowBody(
                      functionalTestingRevision,
                      isStatusInHistory("SECURITYSCAN")
                        ? "TESTING_NOT_IMPLEMENTED"
                        : "NOT_TESTED_YET",
                      release.projectEngineer,
                      getFeedbackDialog(
                        functionalTestingRevision,
                        isStatusInHistory("FUNCTIONAL_TESTING_SKIPPED") &&
                          "NO_TESTS_EXECUTED_TEXT"
                      )
                    ),
                  type: "element",
                },
                {
                  columnName: "RELEASE_VERSION_LABEL",
                  data: release.version,
                },
                {
                  columnName: "PAYMENT_ENGINE_LABEL",
                  options: PaymentEngine,
                  data: release.project?.paymentEngineTypes,
                  type: "list",
                },
                {
                  columnName: "PAYMENT_ENGINE_VERSION_LABEL",
                  type: "editable_select_list",
                  name: "paymentEngineVersions",
                  data: paymentEngineVersions,
                  options: PaymentEngine,
                },
                release.requirementTest.mapiVersion !== "NOT_USED" && {
                  columnName: "MAPI_VERSION_DETAIL_LABEL",
                  data: release.requirementTest?.mapiVersion,
                  type: "editable_select",
                },
                {
                  columnName: "REQUIREMENT_TITLE",
                  data: "TEST_CATALOGUE_BUTTON",
                  function: () =>
                    navigate("/release/detail/testcatalogue", {
                      state: {
                        requirementTest: release.requirementTest,
                      },
                    }),
                  type: "link",
                },
              ]}
            />
            <div className="release-notes-block">
              <CcvText size="medium" color="dark-grey" textStyle="bold">
                {t("RELEASE_NOTES_LABEL")}
              </CcvText>
            </div>
            <Textarea
              className="textarea-medium"
              classNameBlock={"no-spacing textarea-margin-left"}
              translate={t}
              defaultInputData={release.releaseNotes}
              disabled={true}
              testId={"releasenotes"}
            />
          </div>
          <div className="right">
            <CcvText size="medium" color="dark-grey">
              {t("FLOW_DESCRIPTION_LABEL")}
            </CcvText>
            <Textarea
              classNameBlock={"textarea-infininte default-spacing-top "}
              className="textarea-infininte"
              disabled={true}
              defaultInputData={release?.project?.flowDescription}
              testId={"flow_description"}
            />
          </div>
        </div>
      ) : (
        <EmptyIconBlock emptyText="RELEASE_INFO_TEXT_NO_INFO" translate={t} />
      )}
    </>
  );
}

export default FunctionalTestingInfoComponent;
