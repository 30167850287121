import axios from "axios";
import * as qs from "qs";

const RELEASE_REST_API_URL = process.env.REACT_APP_BACKEND_HOST + "/release";

export class ReleaseService {
  authService;

  constructor(authService) {
    this.authService = authService;
  }

  getReleaseById(releaseId) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(RELEASE_REST_API_URL + "/" + releaseId, {
          headers: { Authorization: token },
        });
      });
  }

  getReleaseByIdAndBupaId(releaseId, bupaId) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(RELEASE_REST_API_URL + "/" + releaseId, {
          params: { bupaId: bupaId },
          headers: { Authorization: token },
        });
      });
  }

  getDownloadLink(releaseId, fileType) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(RELEASE_REST_API_URL + "/downloadLink", {
          params: {
            releaseId: releaseId,
            fileType: fileType.key,
          },
          headers: {
            Authorization: token,
          },
        });
      });
  }

  getLatestReleasesByProjectId(projectId) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(RELEASE_REST_API_URL + "?pageSize=3", {
          params: {
            projectId: projectId,
          },
          headers: {
            Authorization: token,
          },
        });
      });
  }

  getReleasesByProjectId(projectId) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(RELEASE_REST_API_URL, {
          params: {
            projectId: projectId,
          },
          headers: {
            Authorization: token,
          },
        });
      });
  }

  createRelease(data) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.post(RELEASE_REST_API_URL, data, {
          headers: {
            Authorization: token,
          },
        });
      });
  }

  getReleaseHistory(releaseId) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(RELEASE_REST_API_URL + "/" + releaseId + "/history", {
          headers: { Authorization: token },
        });
      });
  }

  patchReleaseSecurityScanStatus(releaseId, data) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.patch(
          RELEASE_REST_API_URL + "/" + releaseId + "/securityScanStatus",
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
      });
  }

  patchFunctionalTestingStatus(releaseId, data) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.patch(
          RELEASE_REST_API_URL + "/" + releaseId + "/functionalTestingStatus",
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
      });
  }

  patchReleaseSignedStatus(releaseId, data) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.patch(
          RELEASE_REST_API_URL + "/" + releaseId + "/signedStatus",
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
      });
  }

  patchReleaseUploadToStoreStatus(releaseId, data) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.patch(
          RELEASE_REST_API_URL + "/" + releaseId + "/uploadedToStoreStatus",
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
      });
  }

  patchReleaseNotes(releaseId, releaseNotes) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.patch(
          RELEASE_REST_API_URL + "/" + releaseId + "/releasenotes",
          { releaseNotes: releaseNotes },
          {
            headers: {
              Authorization: token,
            },
          }
        );
      });
  }

  patchReleaseInfo(releaseId, data) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.patch(RELEASE_REST_API_URL + "/" + releaseId, data, {
          headers: { Authorization: token },
        });
      });
  }

  getProjectsWithActions(roles) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(RELEASE_REST_API_URL + "/action", {
          params: {
            roles: roles,
          },
          paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
          headers: {
            Authorization: token,
          },
        });
      });
  }

  getAssignedReleases(user) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(RELEASE_REST_API_URL + "/assignedTasks", {
          params: {
            assignedName: user,
          },

          headers: {
            Authorization: token,
          },
        });
      });
  }

  getTraceabillityList(user) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(RELEASE_REST_API_URL + "/traceability", {
          params: {
            assignedName: user,
          },
          headers: {
            Authorization: token,
          },
        });
      });
  }

  validateApk(apk, projectId) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.post(
          RELEASE_REST_API_URL + "/validateApk/" + projectId,
          apk,
          {
            headers: {
              Authorization: token,
            },
          }
        );
      });
  }
}
