import React, { useEffect, useState } from "react";
import IntegrationType from "../../../domain/IntegrationType";
import TechnologyType from "../../../domain/TechnologyType";
import "../../../style/Table.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReleaseStatus from "../../../domain/ReleaseStatus";
import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import FormatService from "../../../services/FormatService";
import PaymentEngine from "../../../domain/PaymentEngine";
import Segment from "../../../domain/Segment";
import ProjectSolution from "../../../domain/ProjectSolution";
import {
  DataGrid,
  GridToolbar,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid";
import TablePagination from "./TablePagination";
import { usePromiseTracker } from "react-promise-tracker";
import EmptyImageBlock from "../../emptyBlocks/EmptyImageBlock";
import { DataGridTheme, FilterTheme, ToolbarTheme } from "./tableTheme";
import LoadingBlock from "../../Loading/LoadingBlock";

function SmartTable({
  data,
  title,
  filterFields,
  actions,
  promiseArea,
  isDataLoading,
  isFilterDisabled,
  pageSize,
  isNavigationDisabled,
}) {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [columns, setColumns] = useState([]);
  const { promiseInProgress } = usePromiseTracker({ area: promiseArea });

  function getFilterOperatorsValues(dataType) {
    return (
      dataType.filterType === "singleSelect" &&
      Object.values(dataType.enumType).map((type) => {
        return { label: t(type?.description), value: type?.key };
      })
    );
  }

  function getFilterOperators(datatype) {
    switch (datatype?.filterType) {
      case "date":
        return getGridDateOperators().filter(
          (operator) =>
            operator.value === "is" ||
            operator.value === "after" ||
            operator.value === "before"
        );
      case "number":
        return getGridNumericOperators().filter(
          (operator) =>
            operator.value === ">" ||
            operator.value === "<" ||
            operator.value === "="
        );
      case "singleSelect":
        return getGridSingleSelectOperators().filter(
          (operator) => operator.value === "is" || operator.value === "not"
        );
      default:
        return getGridStringOperators().filter(
          (operator) =>
            operator.value === "contains" || operator.value === "equals"
        );
    }
  }

  function getCommaSeperatedString(rowData, lookup) {
    return rowData?.map((value) => t(lookup[value]?.description)).join(", ");
  }

  function getColumnType(field) {
    switch (field) {
      case "solution":
        return {
          enumType: ProjectSolution,
          filterType: "singleSelect",
          filterOperators: "is",
        };
      case "technology":
        return { enumType: TechnologyType, filterType: "singleSelect" };
      case "integrationType":
        return { enumType: IntegrationType, filterType: "singleSelect" };
      case "releaseStatus":
      case "lastReleaseStatus":
        return { enumType: ReleaseStatus, filterType: "singleSelect" };
      case "creationDate":
      case "lastUpdated":
        return { enumType: null, filterType: "date" };
      default:
        return { enumType: null, filterType: "text" };
    }
  }

  function getRowFormat(field, rowData) {
    switch (field) {
      case "creationDate":
      case "lastUpdated":
        return rowData && FormatService.getMediumDateFormat(rowData);
      case "paymentEngineTypes":
        return getCommaSeperatedString(rowData, PaymentEngine);
      case "segments":
        return getCommaSeperatedString(rowData, Segment);
      case "releaseStatus":
      case "lastReleaseStatus":
        return rowData ? t("RELEASESTATUS_" + rowData) : "-";
      case "assignedTo":
        return rowData === "NOT_ASSIGNED" ? "-" : rowData;
      case "solution":
        return ProjectSolution[rowData]?.description;
      default:
        return rowData;
    }
  }

  function getRowWidth(field) {
    switch (field) {
      case "creationDate":
      case "lastUpdated":
        return 200;
      case "releaseStatus":
      case "lastReleaseStatus":
        return 250;
      case "segments":
      case "paymentEngineTypes":
        return 200;
      default:
        return 150;
    }
  }

  function getNavigationColumn() {
    return {
      field: "",
      width: actions?.button ? 200 : 50,
      sortable: false,
      align: "right",
      filterable: false,
      renderCell: () => {
        return actions?.button ? (
          actions.button
        ) : (
          <CcvMaterialIcon iconName="chevron_right" iconStyle="filled" />
        );
      },
    };
  }

  function orderColumns(generatedColumns) {
    return [...generatedColumns].sort(
      (columnA, columnB) =>
        filterFields.indexOf(columnA.field) -
        filterFields.indexOf(columnB.field)
    );
  }

  function getColumns() {
    let generatedColumns = Object.keys(data[0])
      .filter((item) => filterFields.includes(item))
      .map((field) => ({
        headerName: t("SMARTTABLE_" + field.toUpperCase() + "_LABEL"),
        headerAlign: "left",
        field: field,
        type: getColumnType(field).filterType,
        valueFormatter: (rowData) => getRowFormat(field, rowData),
        renderCell: (data) => {
          return (
            data && (
              <CcvText
                size="medium"
                color="dark-grey"
                testId={data.formattedValue}
              >
                {data.formattedValue}
              </CcvText>
            )
          );
        },
        width: getRowWidth(field),
        minWidth: 120,
        flex: 1,
        valueOptions: getFilterOperatorsValues(getColumnType(field)),
        filterOperators: getFilterOperators(getColumnType(field)),
      }));

    generatedColumns = orderColumns(generatedColumns);

    actions?.redirect && generatedColumns.push(getNavigationColumn());
    setColumns(generatedColumns);
  }

  useEffect(() => {
    if (data?.length > 0) {
      getColumns();
    }
  }, [data]);

  return (
    <div className="smarttable">
      {title && (
        <CcvText size="large" textStyle="bold" testId={title.toLowerCase()}>
          {t(title)}
        </CcvText>
      )}
      <DataGrid
        rows={data}
        columns={columns}
        getRowHeight={() => "auto"}
        density="comfortable"
        disableColumnMenu={true}
        disableDensitySelector={true}
        disableColumnSelector={true}
        disableColumnFilter={isFilterDisabled}
        paginationMode={"client"}
        loading={promiseInProgress || isDataLoading}
        rowSelection={false}
        hideFooterSelectedRowCount={true}
        initialState={{
          pagination: {
            paginationModel: { pageSize: pageSize || 10, page: 0 },
          },
        }}
        onRowClick={(rowData) => {
          actions && navigate(actions.redirect + rowData?.id);
        }}
        slots={{
          toolbar: GridToolbar,
          pagination: TablePagination,
          loadingOverlay: () => <LoadingBlock />,
          noResultsOverlay: () => (
            <div className="smarttable-spinner">
              <EmptyImageBlock
                translate={t}
                emptyText={"NO_RESULTS_SMARTTABLE"}
              />
            </div>
          ),
        }}
        localeText={{
          toolbarExport: t("SMARTTABLE_TOOLBAR_EXPORT_TITLE"),
          toolbarExportCSV: t("SMARTTABLE_EXPORT_CSV_TEXT"),
          toolbarQuickFilterPlaceholder: t(
            "SMARTTABLE_TOOLBAR_SEARCH_PLACEHOLDER"
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            printOptions: {
              disableToolbarButton: true,
            },
            csvOptions: {
              disableToolbarButton: true,
            },
          },
          baseTooltip: {
            PopperProps: {
              hidden: true,
            },
          },
          basePopper: {
            sx: ToolbarTheme,
          },
        }}
        sx={DataGridTheme({ isNavigationDisabled: isNavigationDisabled })}
      />
    </div>
  );
}
export default SmartTable;
