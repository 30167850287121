import React from "react";
import {
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  GridPagination,
} from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import { ThemeProvider, createTheme } from "@mui/material";
import { PaginationTheme } from "./tableTheme";

function Pagination({ page, onPageChange, className}) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      className={className}
      size="medium"
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      siblingCount={1}
      boundaryCount={0}
      showFirstButton={true}
      showLastButton={true}
      hideNextButton={true}
      hidePrevButton={true}
    />
  );
}

function TablePagination(props) {
  return (
    <ThemeProvider theme={PaginationTheme}>
      <GridPagination ActionsComponent={Pagination} {...props} />
    </ThemeProvider>
  );
}

export default TablePagination;
