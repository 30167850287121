import React from "react";
import FormValidation from "../../../../../domain/FormValidation";
import ContractCheckbox from "../../../../form/ContractCheckbox";
import Dropzone from "../../../../form/Dropzone";
import InputLabel from "../../../../form/InputLabel";
import RichTextEditor from "../../../../form/RichTextEditor";
import RadioButtonBody from "../RadioButtonBody";

function TestingFinishedBody({
  control,
  translate,
  status,
  setValue,
  validate,
  setIsContractSigned,
  isContractSigned,
  setStatus,
}) {
  return (
    <form>
      <div className="form-inline">
        <div className="input-block-checkbox dialog-left">
          <RadioButtonBody
            control={control}
            translate={translate}
            title={translate("RESULT_LABEL")}
            required={true}
            setValue={setStatus}
            options={[
              {
                id: "success",
                value: "FUNCTIONAL_TESTING_SUCCESS",
                text: "SUCCESS_TEXT",
                icon: "done",
                color: "green",
                testid: "functional_testing_success_option",
              },
              {
                id: "failed",
                value: "FUNCTIONAL_TESTING_FAILED",
                text: "FAILED_TEXT",
                icon: "clear",
                color: "red",
                testid: "functional_testing_failed_option",
              },
            ]}
          />
        </div>
        <div className="dialog-right">
          <Dropzone
            infoMessage={"MAX_PDF_UPLOAD"}
            label={"REPORT_UPLOAD_LABEL"}
            setValue={setValue}
            acceptedExtensions={{ "file/type": [".pdf", ".docx"] }}
            registerName="report"
            maxSize={FormValidation.MAX_REPORT_FILE_SIZE}
            isLabelBold={true}
            shouldValidateOnInput={true}
          />
        </div>
      </div>

      <InputLabel
        labelText={"DIALOG_FEEDBACK"}
        translate={translate}
        bold={true}
        className="richtextbox-label"
        required={status === "FUNCTIONAL_TESTING_FAILED"}
      />

      <RichTextEditor
        maxLength={FormValidation.MAX_FEEDBACK_LENGTH_LARGE}
        setValue={setValue}
        registerName="feedback"
        validate={validate}
        translate={translate}
      />
      {status && (
        <ContractCheckbox
          translate={translate}
          testId={"functional_testing_authorize"}
          setChecked={setIsContractSigned}
          isChecked={isContractSigned}
          registerName={"contract"}
          setValue={setValue}
          text={
            status === "FUNCTIONAL_TESTING_FAILED"
              ? "FUNCTIONAL_TESTING_FAILED_AUTHORIZE_LABEL"
              : "FUNCTIONAL_TESTING_SUCCESS_AUTHORIZE_LABEL"
          }
        />
      )}
    </form>
  );
}

export default TestingFinishedBody;
