const Terminal = {
  IM30: { key: "IM30", description: "IM30" },
  IM15: { key: "IM15", description: "IM15" },
  A920: { key: "A920", description: "A920" },
  A77: { key: "A77", description: "A77" },
  A35: { key: "A35", description: "A35" },
  P400: { key: "P400", description: "P400" },
  OPM_C60_COR_A20: {
    key: "OPM_C60_COR_A20",
    description: "OPM-C60/COR-A20/SCR-C",
  },
  OPM_C60_COR_A10: {
    key: "OPM_C60_COR_A10",
    description: "OPM-C60/COR-A10/SCR-C",
  },
  OPP_C60_COR_A10: {
    key: "OPP_C60_COR_A10",
    description: "OPP-C60/COR-A10/SCR-C",
  },
  CCV_INSYNC: { key: "CCV_INSYNC", description: "CCV InSync" },
};

export default Terminal;
