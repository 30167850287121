import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../config/AppContext";
import "react-toastify/dist/ReactToastify.css";
import "../../style/Layout.scss";
import "../../style/Popup.scss";
import { CcvButton, CcvMaterialIcon } from "@ccv-oc-myccv/ccv-react-components";
import emptyStateImage from "../../assets/images/empty_state.svg";
import SmartTable from "../../components/tables/Smarttable/SmartTable";
import { useNavigate } from "react-router-dom";
import { ShowForRole } from "../../components/ShowForRole";
import Role from "../../domain/Role";
import SpinnerService from "../../services/SpinnerService";
import EmptyImageBlock from "../../components/emptyBlocks/EmptyImageBlock";
import { ProjectService } from "../../services/ProjectService";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";

function HomeComponent(props) {
  const { setTitle, authService } = useContext(AppContext);
  let projectService = new ProjectService(authService);
  const navigate = useNavigate();
  const [roles, setRoles] = useState(null);
  const [projects, setProjects] = useState([
    {
      id: 1,
      lastUpdated: null,
      projectName: null,
      solution: null,
      lastReleaseStatus: null,
      paymentEngineTypes: null,
    },
  ]);
  const [t] = useTranslation();
  const { promiseInProgress } = usePromiseTracker({ area: "project-area" });

  function fetchProjectData() {
    return trackPromise(
      SpinnerService.errorSpinner(
        authService.isEmployee(roles)
          ? fetchProjectsAsEmployee()
          : fetchProjectsAsIntegrator(),
        t,
        null,
        setProjects([])
      ),
      "project-area"
    );
  }

  function fetchProjectsAsEmployee() {
    return projectService.getProjects().then((response) => {
      if (response.status === 200) {
        setProjectData(response.data.content);
      }
    });
  }

  function fetchProjectsAsIntegrator() {
    return projectService
      .getProjectsByBupaId(authService.getBupaId())
      .then((response) => {
        if (response.status === 200) {
          setProjectData(response.data.content);
        }
      });
  }

  function setProjectData(data) {
    setProjects(
      data.sort(
        (projecta, projectb) => projectb.lastUpdated - projecta.lastUpdated
      )
    );
  }

  function getRoles() {
    authService.getRoles().then((response) => {
      setRoles(response);
    });
  }

  useEffect(() => {
    setTitle(props.title);
    getRoles();
  }, []);

  useEffect(() => {
    roles && fetchProjectData();
  }, [roles]);

  return (
    <div className="home" data-testid="SmartTable">
      {!promiseInProgress && projects?.length === 0 ? (
        <EmptyImageBlock
          image={emptyStateImage}
          emptyText={"PROJECTS_INFO_TEXT_NO_PROJECTS_FOUND"}
          translate={t}
        >
          <ShowForRole permission="Developer">
            <CcvButton
              type="primary"
              size="normal"
              text={t("PROJECT_BUTTON_ADD_FIRST")}
              onClick={() => navigate("/" + "createProject")}
              testId="add_project_button"
            />
          </ShowForRole>
        </EmptyImageBlock>
      ) : (
        <>
          <ShowForRole permission="Developer">
            <div
              className="btn-block floating_button"
              data-testid="btnCreateProject"
            >
              <CcvButton
                type="primary"
                size="normal"
                text={t("PROJECT_BUTTON_ADD_PROJECT")}
                onClick={() => navigate("/" + "createProject")}
                disabled={promiseInProgress}
                testId="add_project_button"
              />
            </div>
          </ShowForRole>
          <div className="info-block" data-testid="integrations">
            <SmartTable
              actions={{ redirect: "/project/detail/" }}
              data={projects}
              title={
                authService.isDeveloper(roles) && "SMARTTABLE_PROJECT_TITLE"
              }
              promiseArea={"project-area"}
              filterFields={[
                "lastUpdated",
                "projectName",
                authService.isEmployee(roles) && "bupaName",
                "solution",
                "paymentEngineTypes",
                authService.isEmployee(roles) && "segments",
                "lastReleaseStatus",
              ]}
              pageSize={5}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default HomeComponent;
