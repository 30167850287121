import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../style/HistoryTable.scss";
import "../../style/Table.scss";
import ReleaseStatus from "../../domain/ReleaseStatus";
import InfoDialog from "../dialogs/InfoDialog";
import { ShowForRole } from "../ShowForRole";
import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import EmptyIconBlock from "../emptyBlocks/EmptyIconBlock";
import FormatService from "../../services/FormatService";
import ResultLabel from "../common/ResultLabel";
import LoadingBlock from "../Loading/LoadingBlock";

function HistoryTable(
  {
    showReleaseVersionInRow,
    waitingForBackendResponse,
    data,
    showTitle,
    showFields,
  },
  ...props
) {
  const [t] = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogInfo, setDialogInfo] = useState(null);
  let creationDateCheck = Date.now();
  let previousRow = 0;

  function getFormattedCreationDate(row) {
    return (
      <>
        <div type="date" key={row.creationDate} className="timestamp cell">
          <CcvText size="medium" color="medium-grey">
            {FormatService.getTimeFormat(row.creationDate)}
          </CcvText>
        </div>
        {showReleaseVersionInRow && (
          <div
            type="text"
            key={row.releaseVersion ? row.releaseVersion : t("PROJECT_LABEL")}
            className="cell version"
          >
            <CcvText
              size="medium"
              color="medium-grey"
              testId="release_version_text"
            >
              {row.releaseVersion ? (
                <>
                  {t("RELEASE_TEXT") + " "}
                  <span className="break-word">{row.releaseVersion}</span>
                </>
              ) : (
                t("PROJECT_LABEL")
              )}
            </CcvText>
          </div>
        )}
      </>
    );
  }

  function isStatusUpdate(status) {
    return [
      "SECURITYSCAN_FAILED",
      "SECURITYSCAN_SUCCESS",
      "SIGNING_FAILED",
      "SIGNING_SUCCESS",
      "FUNCTIONAL_TESTING_SKIPPED",
      "FUNCTIONAL_TESTING_FAILED",
      "FUNCTIONAL_TESTING_SUCCESS",
    ].includes(status);
  }

  function getFormattedStatusText(field, row) {
    return (
      <div key={field[1]} className={"history-info cell"}>
        <CcvText
          size="medium"
          color="medium-grey"
          testId={ReleaseStatus[field[1]].historyLabel.toLowerCase()}
        >
          {t(ReleaseStatus[field[1]].historyLabel)}
        </CcvText>
        {isStatusUpdate(field[1]) && (
          <>
            <ResultLabel
              translatedText={t(ReleaseStatus[field[1]].name)}
              status={field[1]}
              testId={ReleaseStatus[field[1]].name.toLowerCase()}
            />

            <span data-testid="feedback_link" className="feedback-link">
              <span
                onClick={() => {
                  setDialogInfo(row);
                  setOpenDialog(true);
                }}
              >
                <CcvText
                  textStyle="link"
                  size="medium"
                  color="medium-grey"
                  testId={ReleaseStatus[row.status]?.reportName?.toLowerCase()}
                >
                  {t(ReleaseStatus[row.status].reportName)}
                </CcvText>
              </span>
            </span>
          </>
        )}
      </div>
    );
  }

  function getFormattedData(field, row) {
    switch (field[0].toLowerCase()) {
      case "creationdate":
        previousRow = row;
        return getFormattedCreationDate(row);
      case "status":
        return getFormattedStatusText(field, row);
      case "revisiontype":
        return field[1] === "INSERT" ? (
          <div key={field[1]} className="history-info one-column cell">
            <CcvText
              size="medium"
              color="medium-grey"
              testId="status_created_text"
            >
              {t("RELEASESTATUS_CREATED")}
            </CcvText>
          </div>
        ) : (
          <div key={field[1]} className="history-info one-column cell">
            <CcvText
              size="medium"
              color="medium-grey"
              testId={
                row.releaseVersion
                  ? "release_edited_text"
                  : "project_edited_text"
              }
            >
              {row.modifiedValues &&
                row.modifiedValues
                  .map((value) => t(value + "_history_field"))
                  .join(", ")}{" "}
              {t("EDITED_TEXT")}
            </CcvText>
          </div>
        );
      case "user":
        return (
          <ShowForRole permission="Employee">
            <div key={field[1]} className="cell user">
              <CcvText size="medium" color="medium-grey">
                {field[1]}
              </CcvText>
            </div>
          </ShowForRole>
        );
      case null:
        return <div key={"-"}>-</div>;
      default:
        return (
          <div key={field[1]}>
            <CcvText size="medium" color="medium-grey">
              {field[1]}
            </CcvText>
          </div>
        );
    }
  }

  function getRecordTitle(creationDate) {
    if (creationDateCheck !== FormatService.getLongDateFormat(creationDate)) {
      creationDateCheck = FormatService.getLongDateFormat(creationDate);
      return (
        <CcvText size="small" color="dark-grey" textStyle="bold">
          <header className="record-title">
            {FormatService.getLongDateFormat(creationDate)}
          </header>
        </CcvText>
      );
    }
  }

  return waitingForBackendResponse ? (
    <LoadingBlock />
  ) : data ? (
    <>
      {dialogInfo && (
        <InfoDialog
          open={openDialog}
          onDisagree={() => setOpenDialog(false)}
          dialogTitle={ReleaseStatus[dialogInfo.status].stepName}
          dialogSubtitle="DIALOG_FEEDBACK"
          dialogInfo={
            dialogInfo && {
              releaseId: dialogInfo.id,
              releaseStatus: dialogInfo.status,
              signing: dialogInfo.signing,
              functionalTest: dialogInfo.functionalTest,
              securityscan: dialogInfo.securityscan,
              date: FormatService.getShortDateFormat(dialogInfo.creationDate),
              time: FormatService.getTimeFormat(dialogInfo.creationDate),
              ...dialogInfo,
            }
          }
          releaseStatus={dialogInfo.status}
        />
      )}
      {Object.values(data).map(
        (row) =>
          row.creationDate !== previousRow.creationDate && (
            <div className="record">
              {showTitle ? getRecordTitle(row.creationDate) : ""}
              <div className="record-flex-container">
                {Object.entries(row)
                  .filter((item) => showFields.includes(item[0]))
                  .map((item) => getFormattedData(item, row))}
              </div>
            </div>
          )
      )}
    </>
  ) : (
    <EmptyIconBlock emptyText={"PROJECT_NO_HISTORY_LABEL"} translate={t} />
  );
}

export default HistoryTable;
