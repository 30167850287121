import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../config/AppContext";
import HistoryTable from "../../components/tables/HistoryTable";
import "../../style/Detail.scss";
import UpdateDialog from "../../components/dialogs/UpdateDialog";
import {
  CcvButton,
  CcvHeading,
  CcvMessage,
} from "@ccv-oc-myccv/ccv-react-components";
import StepIndicator from "../../components/progressbars/StepIndicator";
import ReleaseStatus from "../../domain/ReleaseStatus";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ShowForRole } from "../../components/ShowForRole";
import ReleaseInfoComponent from "./navigatorComponents/infoReleaseComponents/ReleaseInfoComponent";
import SpinnerService from "../../services/SpinnerService";
import TabNavigator from "../../components/TabNavigator";
import FunctionalTestingInfoComponent from "./navigatorComponents/infoReleaseComponents/FunctionalTestingInfoComponent";
import SigningInfoComponent from "./navigatorComponents/infoReleaseComponents/SigningInfoComponent";
import SecurityInfoComponent from "./navigatorComponents/infoReleaseComponents/SecurityInfoComponent";
import ApplicationInfoComponent from "./navigatorComponents/infoReleaseComponents/ApplicationInfoComponent";
import { ReleaseService } from "../../services/ReleaseService";
import { ProjectService } from "../../services/ProjectService";
import multiDownload from "multi-download";
import Role from "../../domain/Role";
import { CcvStoreInfoService } from "../../services/CcvStoreInfoService";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { Spinner } from "../../components/Loading/Spinner";
import { toast } from "react-toastify";
import FormatService from "../../services/FormatService";
import ContractCheckbox from "../../components/form/ContractCheckbox";
import DownloadTypes from "../../domain/DownloadType";

function ReleaseDetailComponent(props) {
  const { setTitle, setProjectInfo, authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);
  let ccvStoreService = new CcvStoreInfoService(authService);
  let projectService = new ProjectService(authService);
  const [roles, setRoles] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const releaseId = useParams().id;
  const [release, setRelease] = useState();
  const [releaseHistory, setReleaseHistory] = useState();
  const [ccvStoreInfo, setCcvStoreInfo] = useState();
  const [activeSection, setActiveSection] = useState("GENERAL_INFO");
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isUpdatingRelease, setIsUpdatingRelease] = useState(false);
  const [t] = useTranslation();
  const [paymentEngineVersions, setPaymentEngineVersions] = useState();
  const [downloadFileType, setDownloadFileType] = useState(
    DownloadTypes.UNSIGNED_APK
  );
  const [activeBodyInfo, setActiveBodyInfo] = useState();

  function getRoles() {
    authService.getRoles().then((response) => {
      setRoles(response);
    });
  }

  async function fetchReleaseData() {
    if (authService.isEmployee(roles)) {
      return fetchReleaseInfoAsEmployee();
    } else {
      return fetchReleaseInfoAsDeveloper();
    }
  }

  function fetchReleaseInfoAsEmployee() {
    return releaseService
      .getReleaseById(releaseId)
      .then((response) => {
        setReleaseData(response.data);
        fetchReleaseHistoryAndCcvStoreInfo(response.data.project.id);
      })
      .catch((err) => {
        fetchReleaseHistoryAndCcvStoreInfo();
        throw err;
      });
  }

  function fetchReleaseInfoAsDeveloper() {
    return releaseService
      .getReleaseByIdAndBupaId(releaseId, authService.getBupaId())
      .then((response) => {
        setReleaseData(response.data);
        response?.data?.project &&
          fetchReleaseHistoryAndCcvStoreInfo(response.data.project.id);
      })
      .catch((err) => {
        if ([401, 403].includes(err.response?.status)) {
          setIsAccessDenied(true);
          toast(
            <CcvMessage
              type="error"
              text={t("TOAST_ACCESS_DENIED")}
              testId="not_your_release_error"
            />
          );
        } else {
          fetchReleaseHistoryAndCcvStoreInfo();
          throw err;
        }
      });
  }

  async function fetchCcvStoreInfo(projectId) {
    return trackPromise(
      ccvStoreService.getCcvStoreInfo(projectId).then((response) => {
        setCcvStoreInfo(response.data);
      }),
      "ccvStore-area"
    );
  }

  async function fetchReleaseHistory() {
    return trackPromise(
      SpinnerService.errorSpinner(
        releaseService.getReleaseHistory(releaseId).then((response) => {
          setReleaseHistory(response.data);
        }),
        t
      ),
      "history-area"
    );
  }

  function fetchReleaseHistoryAndCcvStoreInfo(projectId) {
    SpinnerService.errorSpinner(
      Promise.all([fetchReleaseHistory(), fetchCcvStoreInfo(projectId)]),
      t
    );
  }

  function setReleaseData(responseData) {
    setRelease(responseData);
    setPaymentEngineVersions(
      setInitialEngineVersions(
        responseData.requirementTest.paymentEngineVersions
      )
    );
    setTitle(t(props.title) + " " + responseData.version);
    setProjectInfo(responseData.project);
    setActiveSection(
      localStorage.getItem("activeSection")
        ? localStorage.getItem("activeSection")
        : ReleaseStatus[responseData.releaseStatus].activeTabSection
    );
    localStorage.removeItem("activeSection");
  }

  function assignReleaseTo(user) {
    setIsUpdatingRelease(true);
    SpinnerService.defaultSpinner(
      releaseService.patchReleaseInfo(releaseId, {
        lastEditedBy: release.lastEditedBy,
        lastUpdated: release.lastUpdated,
        assignedTo: user,
      }),
      t,
      "TOAST_PROCESSING",
      user === "NOT_ASSIGNED"
        ? "TOAST_RELEASE_UNASSIGNED"
        : "TOAST_RELEASE_ASSIGNED_TO",
      setIsUpdatingRelease
    );
  }

  function CheckAssignedAndDownload(fileType) {
    if (
      authService.isEmployee(roles) &&
      [
        ReleaseStatus.SECURITYSCAN_SUCCESS.key,
        ReleaseStatus.FUNCTIONAL_TESTING_SKIPPED.key,
        ReleaseStatus.FUNCTIONAL_TESTING_SUCCESS.key,
      ].includes(release.releaseStatus)
    ) {
      if (
        release.assignedTo === authService.getEmail() ||
        !ReleaseStatus[release.releaseStatus].infoLabelEmployee.assignOption
      ) {
        return downloadFromSharepoint(fileType);
      }
      setActiveBodyInfo(
        ReleaseStatus[release.releaseStatus].infoLabelEmployee.dialogInfo
      );
      setDownloadFileType(fileType);
      setOpenDialog(true);
    } else {
      return downloadFromSharepoint(fileType);
    }
  }

  function downloadFromSharepoint(fileType) {
    SpinnerService.defaultSpinner(
      releaseService.getDownloadLink(release.id, fileType).then((response) => {
        return FormatService.downloadFileWithNewFilename(
          response.data.downloadUrl,
          fileType === DownloadTypes.UNSIGNED_APK
            ? FormatService.getUnsignedFileName(
                response.data.fileName,
                release.project?.apkInfo?.packageName,
                release.version,
                release.project?.projectName
              )
            : response.data.fileName
        );
      }),
      t,
      "TOAST_DOWNLOADING",
      "TOAST_DOWNLOAD_SUCCEEDED"
    );
  }

  function downloadImagesFromSharepoint(fileType, files) {
    return SpinnerService.defaultSpinner(
      Promise.all(
        files.map((file) =>
          projectService
            .getDownloadLink(release.project.id, fileType, file.name)
            .then((response) => {
              return response.data;
            })
        )
      ).then((urls) => {
        multiDownload(urls);
      }),
      t,
      "TOAST_DOWNLOADING",
      "TOAST_DOWNLOAD_SUCCEEDED"
    );
  }

  function setInitialEngineVersions(paymentEngineVersions) {
    return paymentEngineVersions.map((engine) => {
      return {
        key: engine.name,
        value: engine.version,
        isOtherOptionSelected: false,
        initialValue: engine.version,
      };
    });
  }

  useEffect(() => {
    location.state?.detail?.version &&
      setTitle(t(props.title) + " " + location.state.detail.version);
    getRoles();
  }, []);

  useEffect(() => {
    if (release && !isUpdatingRelease) {
      fetchReleaseData();
    }
  }, [isUpdatingRelease]);

  useEffect(() => {
    roles &&
      trackPromise(
        SpinnerService.errorSpinner(fetchReleaseData(), t),
        "release-area"
      );
  }, [roles]);

  function openUpdateForm() {
    if (
      ["FUNCTIONAL_TESTING_SKIPPED", "FUNCTIONAL_TESTING_SUCCESS"].includes(
        release.releaseStatus
      )
    ) {
      navigate("/release/detail/securityscan", {
        state: {
          release: release,
        },
      });
    }
    setActiveBodyInfo({
      key: release.releaseStatus,
      title: ReleaseStatus[release.releaseStatus]?.nextActionTitle,
    });
    setOpenDialog(true);
  }

  function getUpdateButton() {
    if (ReleaseStatus[release.releaseStatus].nextAction) {
      return (
        <ShowForRole permission="Employee">
          <div className="status-button">
            <CcvButton
              size="normal"
              type="primary"
              text={t(ReleaseStatus[release.releaseStatus]?.nextAction)}
              onClick={() => openUpdateForm()}
              testId={ReleaseStatus[
                release.releaseStatus
              ]?.nextAction?.toLowerCase()}
            />
          </div>
          {activeBodyInfo && (
            <>
              <UpdateDialog
                open={openDialog}
                onDisagree={() => setOpenDialog(false)}
                popupTitle={t(
                  activeBodyInfo.title
                    ? activeBodyInfo.title
                    : release.assignedTo === "NOT_ASSIGNED"
                    ? activeBodyInfo.titleUnassigned
                    : activeBodyInfo.titleAssigned
                )}
                releaseId={releaseId}
                bodyKey={activeBodyInfo.key}
                updating={isUpdatingRelease}
                setUpdating={setIsUpdatingRelease}
                downloadApkInfo={downloadFromSharepoint}
                downloadFileType={downloadFileType}
                release={release}
                assignedInfo={activeBodyInfo}
              />
            </>
          )}
        </ShowForRole>
      );
    }
  }

  function getEmployeeMessage() {
    if (
      release.assignedTo != "NOT_ASSIGNED" &&
      release.assignedTo != authService.getEmail()
    ) {
      return (
        <CcvMessage
          text={
            <div className="message-block">
              <p>
                {t(
                  ReleaseStatus[release.releaseStatus].infoLabelEmployee
                    .assignedText
                )}{" "}
                "{release.assignedTo}"
              </p>
            </div>
          }
          type={"info"}
          testId={ReleaseStatus[
            release.releaseStatus
          ].infoLabelEmployee.assignedText?.toLowerCase()}
        />
      );
    } else {
      return (
        ReleaseStatus[release.releaseStatus].infoLabelEmployee && (
          <CcvMessage
            text={
              <div className="message-block message-block-row">
                <p>
                  {t(
                    release.assignedTo === authService.getEmail()
                      ? ReleaseStatus[release.releaseStatus].infoLabelEmployee
                          .textAssigned
                      : ReleaseStatus[release.releaseStatus].infoLabelEmployee
                          .textUnassigned
                  )}
                </p>
                {ReleaseStatus[release.releaseStatus].infoLabelEmployee
                  .assignOption && (
                  <ContractCheckbox
                    translate={t}
                    testId={"assign_to_me_checkbox"}
                    setCheckedFunction={(item) =>
                      assignReleaseTo(
                        release.assignedTo === "NOT_ASSIGNED"
                          ? authService.getEmail()
                          : "NOT_ASSIGNED"
                      )
                    }
                    isChecked={release.assignedTo != "NOT_ASSIGNED"}
                    disabled={isUpdatingRelease}
                    text={
                      ReleaseStatus[release.releaseStatus].infoLabelEmployee
                        .checkboxText
                    }
                  />
                )}
              </div>
            }
            type={"info"}
            testId={(release.assignedTo === authService.getEmail()
              ? ReleaseStatus[release.releaseStatus].infoLabelEmployee
                  .textAssigned
              : ReleaseStatus[release.releaseStatus].infoLabelEmployee
                  .textUnassigned
            ).toLowerCase()}
          />
        )
      );
    }
  }

  function getIntegratorMessage() {
    return (
      <CcvMessage
        text={
          <div className="message-block">
            <p>{t(ReleaseStatus[release.releaseStatus].infoLabel)}</p>
            {[
              "SIGNING_FAILED",
              "SECURITYSCAN_FAILED",
              "FUNCTIONAL_TESTING_FAILED",
            ].includes(release.releaseStatus) && (
              <CcvButton
                type="primary"
                size="small"
                text={t("CREATE_NEW_RELEASE_TEXT")}
                onClick={(event) => {
                  navigate("/release/createRelease", {
                    state: {
                      projectId: release?.project?.id,
                      projectName: release?.project?.projectName,
                      integrationType: release?.project?.integrationType,
                      paymentEngineTypes: release?.project?.paymentEngineTypes,
                    },
                  });
                }}
                testId="create_release_button"
                disabled={!release}
              />
            )}
          </div>
        }
        type={release.releaseStatus.includes("FAILED") ? "error" : "info"}
        testId={ReleaseStatus[release.releaseStatus].infoLabel?.toLowerCase()}
      />
    );
  }

  function getStatusMessage() {
    return (
      <>
        <ShowForRole permission="Developer">
          {getIntegratorMessage()}
        </ShowForRole>
        <ShowForRole permission="Employee">
          {release &&
            ReleaseStatus[release.releaseStatus].infoLabelEmployee &&
            getEmployeeMessage()}
        </ShowForRole>
      </>
    );
  }

  return (
    <>
      <div className="content-block">
        {release && releaseHistory && (
          <div className="progress-block">
            <Spinner
              area="release-area"
              dataIsFetched={releaseHistory}
              accessDenied={isAccessDenied}
            >
              <span className="stepper-block">
                <StepIndicator
                  statusPool={ReleaseStatus}
                  currentStatus={release.releaseStatus}
                  possibleSteps={[
                    "CREATED",
                    "FUNCTIONAL_TESTING_SUCCESS",
                    "SECURITYSCAN_SUCCESS",
                    "SIGNING_SUCCESS",
                    "AVAILABLE_IN_STORE",
                  ]}
                />
                {getUpdateButton()}
              </span>
              {getStatusMessage()}
            </Spinner>
          </div>
        )}
        <TabNavigator
          sections={{
            GENERAL_INFO: {
              title: "RELEASE_INFO_HEADING",
              block: (
                <Spinner
                  area="release-area"
                  dataIsFetched={release}
                  accessDenied={isAccessDenied}
                >
                  <ReleaseInfoComponent
                    release={release}
                    releaseHistory={releaseHistory}
                    setIsUpdatingRelease={setIsUpdatingRelease}
                    downloadFromSharepoint={() =>
                      CheckAssignedAndDownload(DownloadTypes.UNSIGNED_APK)
                    }
                    setInitialEngineVersions={setInitialEngineVersions}
                    setPaymentEngineVersions={setPaymentEngineVersions}
                    paymentEngineVersions={paymentEngineVersions}
                  />
                </Spinner>
              ),
              activated: true,
            },
            FUNCTIONAL_TESTING: {
              title: "FUNCTIONAL_TESTING_HEADING",
              block: (
                <Spinner area="release-area" dataIsFetched={release}>
                  <FunctionalTestingInfoComponent
                    release={release}
                    downloadFromSharepoint={() =>
                      CheckAssignedAndDownload(DownloadTypes.UNSIGNED_APK)
                    }
                    releaseHistory={releaseHistory}
                    paymentEngineVersions={paymentEngineVersions}
                  />
                </Spinner>
              ),
              activated: true,
            },
            SECURITYSCAN: {
              title: "SECURITYSCAN_HEADING",
              block: (
                <Spinner
                  area="history-area"
                  accessDenied={isAccessDenied}
                  dataIsFetched={releaseHistory}
                >
                  <SecurityInfoComponent
                    release={release}
                    roles={roles}
                    downloadFromSharepoint={CheckAssignedAndDownload}
                    releaseHistory={releaseHistory}
                  />
                </Spinner>
              ),
              activated: true,
            },
            SIGNING: {
              title: "SIGNING_HEADING",
              block: (
                <Spinner
                  area="history-area"
                  accessDenied={isAccessDenied}
                  dataIsFetched={releaseHistory}
                >
                  <SigningInfoComponent
                    release={release}
                    downloadFromSharepoint={() =>
                      CheckAssignedAndDownload(DownloadTypes.UNSIGNED_APK)
                    }
                    releaseHistory={releaseHistory}
                  />
                </Spinner>
              ),
              activated: true,
            },
            APPLICATION_INFO: {
              title: "APPLICATION_INFO_HEADING",
              block: (
                <Spinner
                  area="ccvStore-area"
                  accessDenied={isAccessDenied}
                  dataIsFetched={ccvStoreInfo}
                >
                  <ApplicationInfoComponent
                    release={release}
                    ccvStoreInfo={ccvStoreInfo}
                    downloadFromSharepoint={downloadFromSharepoint}
                    downloadImagesFromSharepoint={downloadImagesFromSharepoint}
                  />
                </Spinner>
              ),
              activated: true,
            },
          }}
          activeSection={activeSection}
        />

        <div className="info-block" data-testid="releasehistory">
          <Spinner
            area="history-area"
            accessDenied={isAccessDenied}
            dataIsFetched={releaseHistory}
          >
            <CcvHeading
              text={t("RELEASE_HISTORY_LABEL")}
              size="small"
              testId="release_history_title"
            />
            <HistoryTable
              data={releaseHistory}
              showFields={["creationDate", "status", "user", "revisionType"]}
              feedback={release?.feedback}
              showTitle={true}
              showReleaseVersionInRow={false}
              releaseId={release?.id}
              lastUserUpdate={{
                user: release?.lastEditedBy,
                release: release?.version,
              }}
            />
          </Spinner>
        </div>
      </div>
    </>
  );
}

export default ReleaseDetailComponent;
