import * as yup from "yup";

export function SecurityscanYupValidation(securityscan) {
  return yup.object({
    status: yup.string().required("required").typeError("required"),
    report: yup
      .array()
      .when(() =>
        isMobsfReportRequired()
          ? yup.array().min(1).max(1).required("required")
          : yup.array().min(0).notRequired()
      ),
    mobsfUrl: yup.string().url().notRequired(),
    jottiUrl: yup.string().when(() => {
      return isJottiReportRequired()
        ? yup.string().url().required("required").typeError("required")
        : yup.string().url().notRequired();
    }),
    feedback: yup.string().notRequired(),
    contract: yup
      .string()
      .when("status", ([status]) =>
        status === "SECURITYSCAN_FAILED"
          ? yup.boolean().notRequired()
          : yup
              .boolean()
              .required("required")
              .oneOf([true], "The terms and conditions must be accepted.")
      ),
  });

  function isMobsfReportRequired() {
    return !["FINISHED", "RUNNING"].includes(securityscan?.mobsf?.progress);
  }

  function isJottiReportRequired() {
    return !["FINISHED", "RUNNING", "JOTTI_DISABLED"].includes(
      securityscan?.jotti?.progress
    );
  }
}
