import {
  CcvButton,
  CcvMaterialIcon,
  CcvText,
} from "@ccv-oc-myccv/ccv-react-components";
import React from "react";
import FormatService from "../../../../services/FormatService";
import DownloadTypes from "../../../../domain/DownloadType";
import ScannerResult from "../../../common/ScannerResult";
import ResultLabel from "../../../common/ResultLabel";
import InfoTextWithIcon from "../../../common/InfoTextWithIcon";
import { ShowForRole } from "../../../ShowForRole";

function SecurityScanBody({
  downloadFromSharepoint,
  dialogInfo,
  dialogSubtitle,
  translate,
}) {
  function navigateToUrl(url) {
    window.open(url, "_blank");
  }

  function getResultLabel(text, status) {
    return (
      <ResultLabel
        status={status}
        translatedText={translate(text).toLocaleUpperCase()}
        testId={"scan_result_label_" + status}
      />
    );
  }

  function renderExceededMaxEntriesResult(length) {
    return (
      <span className="scanner-malware-result">
        <CcvText color="red">
          <CcvMaterialIcon iconName="clear" />
        </CcvText>
        <CcvText size="medium">
          {length + " " + translate("ERRORS_FOUND_TEXT")}
        </CcvText>
      </span>
    );
  }

  function renderBodyFailed(scannerResults) {
    return scannerResults.map((resultItem) => (
      <>
        <ScannerResult
          status="FAILED"
          resultName={resultItem.name}
          resultDescription={resultItem.description}
        />
        <CcvText size="small" color="red" testId="package_name_jotti">
          {resultItem.packageName}
        </CcvText>
      </>
    ));
  }

  function getFailedSecurityscanResultsLength(scannerResults) {
    return scannerResults?.filter((resultItem) => resultItem.packageName)
      .length;
  }

  function getJottiBody(securityscan) {
    const scannerResults = securityscan?.jotti?.scannerResults;
    switch (securityscan?.jotti?.progress) {
      case "RUNNING":
        return getResultLabel(
          "SCAN_ONGOING_LABEL",
          "ongoing",
          "jotti_scan_result_label_"
        );
      case "EXECUTED_MANUALLY":
        return getResultLabel(
          "MANUALLY_EXECUTED_LABEL",
          "inactive",
          "jotti_scan_result_label_"
        );
      case "FINISHED":
        return getFinishedJottiBody(scannerResults);
      default:
        return (
          <CcvText size="medium" color="medium-grey" testId="no_info_jotti">
            {translate("RELEASE_INFO_TEXT_NO_INFO")}
          </CcvText>
        );
    }
  }

  function getFinishedJottiBody(scannerResults) {
    if (getFailedSecurityscanResultsLength(scannerResults) === 0) {
      return getResultLabel("OK", "jotti_success");
    }
    if (getFailedSecurityscanResultsLength(scannerResults) > 3) {
      return renderExceededMaxEntriesResult(
        getScannerResultList(scannerResults).length
      );
    }
    return renderBodyFailed(scannerResults);
  }

  function getScannerResultList(scannerResults) {
    return scannerResults
      ?.filter((ScannerResult) => ScannerResult.packageName)
      .map((scannerResult) => {
        return {
          name: scannerResult.name,
          description: scannerResult.packageName,
          status: "FAILED",
        };
      });
  }

  return (
    <div className="feedback-container security-scan-feedback-container">
      <div className="feedback-heading">
        <CcvText
          size="small"
          color="dark-grey"
          textStyle="bold"
          testId={dialogSubtitle.toLowerCase()}
        >
          {translate(dialogSubtitle)}
        </CcvText>
        <CcvText size="small" color="medium-grey">
          {dialogInfo.date} {dialogInfo.time}
        </CcvText>
      </div>
      <CcvText
        size="medium"
        color={dialogInfo.securityscan?.feedback ? "dark-grey" : "medium-grey"}
        testId="feedback_container"
      >
        {dialogInfo.securityscan?.feedback ? (
          <div
            data-testid="feedback_result_security_scan"
            dangerouslySetInnerHTML={{
              __html: FormatService.getHTMLFormat(
                dialogInfo.securityscan.feedback
              ),
            }}
          />
        ) : (
          translate("NO_FEEDBACK_TEXT")
        )}
      </CcvText>
      <div className="feedback-heading">
        <CcvText
          size="small"
          color="dark-grey"
          textStyle="bold"
          testId="jotti_malware_scan_label"
        >
          {translate("JOTTI_MALWARE_SCAN_LABEL")}
        </CcvText>
        {dialogInfo.securityscan?.jotti?.scanJobIdUrl && (
          <span
            data-testid="jotti_report_link_label"
            className="link-with-icon"
            onClick={() =>
              navigateToUrl(dialogInfo.securityscan?.jotti?.scanJobIdUrl)
            }
          >
            <CcvText size="medium" color="blue">
              {translate("JOTTI_RAPORT_LINK_LABEL")}
            </CcvText>
            <CcvMaterialIcon
              iconName="launch"
              size="extra-small"
              iconStyle="outlined"
            />
          </span>
        )}
      </div>
      {getJottiBody(dialogInfo.securityscan)}
      <div className="feedback-heading">
        <CcvText
          size="small"
          color="dark-grey"
          textStyle="bold"
          testId="mobsf_scan_label"
        >
          {translate("MOBSF_RAPPORT_LABEL")}
        </CcvText>
      </div>

      {dialogInfo?.securityscan?.reportFileAvailable ? (
        <div
          className="report-download"
          data-testid="download_report"
          onClick={() =>
            downloadFromSharepoint(DownloadTypes.SECURITYSCAN_REPORT)
          }
        >
          <span className="report-download-tekst">
            <CcvText size="medium" color="dark-grey">
              <CcvMaterialIcon
                iconName="description"
                size="medium"
                iconStyle="outlined"
              />
              {translate("SECURITY_REPORT")}
            </CcvText>
          </span>
          <CcvMaterialIcon
            iconName="file_download"
            size="medium"
            iconStyle="outlined"
          />
        </div>
      ) : (
        <CcvText size="medium" color="medium-grey">
          {translate("REPORT_NOT_AVAILABLE_TEXT")}
        </CcvText>
      )}
    </div>
  );
}

export default SecurityScanBody;
