import React, { useEffect, useState } from "react";
import Dropzone from "../../../../components/form/Dropzone";
import FormValidation from "../../../../domain/FormValidation";
import {
  CcvButton,
  CcvHeading,
  CcvMaterialIcon,
  CcvMessage,
  CcvText,
} from "@ccv-oc-myccv/ccv-react-components";
import { useTranslation } from "react-i18next";
import LoadingBlock from "../../../../components/Loading/LoadingBlock";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ApkValidationInfo } from "../../../../domain/ApkValidationInfo";
import FormatService from "../../../../services/FormatService";

function ApkUploadComponent({
  setValue,
  clearErrors,
  errors,
  setIsSaveButtonDisabled,
  setApkInfo,
  apkInfo,
  releaseService,
  projectId,
  setNextButtonText,
  setApkVersionName,
}) {
  const [t] = useTranslation();
  const [apk, setApk] = useState();
  const [errorText, setErrorText] = useState();
  const { promiseInProgress } = usePromiseTracker({ area: "validate-apk" });

  function validateApk(apk) {
    setIsSaveButtonDisabled(true);
    setErrorText();
    setApkInfo();
    trackPromise(
      releaseService
        .validateApk(getFormData(apk), projectId)
        .then((response) => {
          setApkInfo(response.data);
          setApkValues(response.data);
          setApkVersionName(response.data.versionName);
        })
        .catch((err) => {
          setErrorText(
            err?.response?.data?.translatableCode
              ? err.response.data.translatableCode
              : "VALIDATION_API_CALL_FAILED_TEXT"
          );
        }),
      "validate-apk"
    );
  }

  function getFormData(apk) {
    const formData = new FormData();
    formData.append(
      "apk",
      new File([apk], FormatService.replaceSpecialChar(apk.name), {
        type: apk.type,
      })
    );
    return formData;
  }

  function setApkValues(responseData) {
    setValue("sha256", responseData.sha256);
    setValue("packageName", responseData.packageName);
    setValue("versionCode", responseData.versionCode);
    setValue("versionName", responseData.versionName);
    setValue("signatures", responseData.signatures);
  }

  useEffect(() => {
    apk?.length > 0 ? validateApk(apk[0]) : setIsSaveButtonDisabled(true);
    !apk && setApkInfo();
    !apk && setErrorText();
  }, [apk]);

  useEffect(() => {
    promiseInProgress
      ? setNextButtonText("VALIDATING_BUTTON_TEXT")
      : setNextButtonText("NEXT");
  }, [promiseInProgress]);

  function getApkValidationBody() {
    if (apkInfo?.validationErrors?.length > 0) {
      return getValidationErrorBody();
    }
    if (apkInfo) {
      return getValidationSuccessBody();
    }
    if (errorText) {
      return getApiFailedBody();
    }
    return getApkValidionInitialBody();
  }

  function getApiFailedBody() {
    return (
      <CcvMessage
        text={
          <div className="validation-error-result">
            <span>
              <CcvMaterialIcon iconName="info" />
              {t(errorText)}
            </span>
          </div>
        }
        type="error"
        testId="apk_validation_call_failed"
      />
    );
  }

  function getValidationSuccessBody() {
    setIsSaveButtonDisabled(false);
    return (
      <CcvMessage
        text={
          <span>
            <CcvMaterialIcon iconName="check" />
            {t("APK_VALIDATED_SUCCESS_TEXT")}
          </span>
        }
        type="success"
        testId="apk_validation_success"
      />
    );
  }

  function getValidationErrorBody() {
    return (
      <>
        <span className="validation-error-label">
          <CcvText size="medium" color="red">
            {t("VALIDATION_ERROR_TEXT")}
          </CcvText>
        </span>
        {apkInfo?.validationErrors
          ?.filter((error) => {
            return !apkInfo.validationErrors.includes(
              ApkValidationInfo[error]?.except
            );
          })
          .map((error) => {
            return (
              <CcvMessage
                text={
                  <div className="validation-error-result">
                    <span>
                      <CcvMaterialIcon iconName="info" />
                      {t(ApkValidationInfo[error]?.infoText)}
                    </span>
                    {ApkValidationInfo[error]?.url && (
                      <span className="validation-error-link">
                        <CcvButton
                          type="link"
                          text={t("LINK_TO_DOCUMENTATION_TEXT")}
                          testId="error_docu_link"
                          onClick={() =>
                            window.open(ApkValidationInfo[error].url, "blank")
                          }
                        />
                      </span>
                    )}
                  </div>
                }
                type="error"
                testId={ApkValidationInfo[error]?.infoText?.toLowerCase()}
              />
            );
          })}
      </>
    );
  }

  function getApkValidionInitialBody() {
    return (
      <div className="list-of-checks">
        <CcvHeading
          testId="list_of_checks_text"
          size="extra-small"
          text={t("LIST_OF_CHECKS_TEXT")}
        />
        <CcvText testId="list_of_checks" size="medium">
          <ul>
            <li>{t("CHECK_SIGNED_WITH_V2")}</li>
            <li>{t("CHECK_PACKAGE_NAME")}</li>
            <li>{t("CHECK_VERSIONCODE")}</li>
            <li>{t("CHECK_VERSIONNAME")}</li>
            <li>{t("CHECK_PERMISSION")}</li>
            <li>{t("CHECK_DEBUG")}</li>
            <li>{t("CHECK_DEBUG_MANIFEST")}</li>
            <li>{t("WARNING_NEPTUNE_API_FOUND")}</li>
          </ul>
        </CcvText>
      </div>
    );
  }

  return (
    <div className="block full-width">
      <CcvHeading
        testId="validating_apk_title"
        size="medium"
        text={t("RELEASE_APK_UPLOAD_LABEL")}
        level={1}
      />
      <Dropzone
        setValue={setValue}
        setCustomValue={setApk}
        clearError={clearErrors}
        shouldValidateOnInput={false}
        errors={errors}
        errorText="TOAST_APK_REQUIRED"
        acceptedExtensions={{ "file/type": [".apk"] }}
        registerName="apk"
        maxSize={FormValidation.MAX_APK_FILE_SIZE}
        required={true}
        disabled={promiseInProgress}
        processing={promiseInProgress}
      />
      <div className="detail-spacing">
        <CcvHeading
          testId="validating_apk_title"
          size="small"
          text={t("VALIDATING_APK_TITLE")}
        />
        <CcvText testId="validating_apk_text" size="medium">
          {t("VALIDATING_APK_TEXT")}
        </CcvText>
      </div>
      <div className="validation-result">
        {promiseInProgress ? (
          <LoadingBlock
            className="createRelease"
            translatedText={t("VALIDATING_BUTTON_TEXT")}
          />
        ) : (
          getApkValidationBody()
        )}
      </div>
    </div>
  );
}

export default ApkUploadComponent;
