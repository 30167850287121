import {
  CcvMaterialIcon,
  CcvRadioButton,
  CcvText,
} from "@ccv-oc-myccv/ccv-react-components";
import React from "react";
import { Controller } from "react-hook-form";
import ValidationError from "../../../form/ValidationError";

function RadioButtonBody({
  control,
  translate,
  setValue,
  errors,
  options,
  name,
  title,
  selectedOption,
  isRegisterDisabled,
  required,
}) {
  return (
    <div>
      <CcvText textStyle="semi-bold" size="medium">
        {translate(title) + (required ? " *" : "")}
      </CcvText>
      <Controller
        control={control}
        name={name ? name : "status"}
        render={({ field: { onChange } }) => (
          <div className="update-dialog-radio">
            {options.map((option) => (
              <>
                <span className="radio-button-container">
                  <CcvRadioButton
                    labelText={
                      <span
                        className={
                          (option.tooltipText && "option-with-extra-text") +
                          " radio-button-with-icon selectable"
                        }
                        data-testid={option.testid}
                      >
                        <span className="radio-button-text">
                          {option.icon && (
                            <CcvText
                              color={option.color}
                              testId={option.testid}
                            >
                              <CcvMaterialIcon
                                iconName={option.icon}
                                size="normal"
                                iconStyle="outlined"
                              />
                            </CcvText>
                          )}
                          <CcvText size="medium">
                            {translate(option.text)}
                          </CcvText>
                        </span>
                      </span>
                    }
                    id={option.id}
                    groupName={name ? name : "status"}
                    key={option.id}
                    value={option.value}
                    checked={selectedOption && option.value === selectedOption}
                    onChange={(item) => {
                      !isRegisterDisabled && onChange(item);
                      setValue(item);
                    }}
                  />
                </span>
                <span className="radio-button-tooltip">
                  {option.tooltipText && (
                    <CcvText
                      testId={option.tooltipText.toLowerCase()}
                      size="x-small"
                      color="medium-grey"
                    >
                      {translate(option.tooltipText)}
                    </CcvText>
                  )}
                </span>
              </>
            ))}
          </div>
        )}
      />
      {errors && (
        <ValidationError
          error={errors["status"]}
          errorText={translate("DIALOG_STATUS_INFO_TEXT_CHOOSE_OPTION")}
          testId="status_error"
        />
      )}
    </div>
  );
}

export default RadioButtonBody;
