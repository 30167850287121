import React from "react";
import PermissionInput from "./PermissionInput";

function PermissionList({ permissions }) {
  function sortPermissions(permissions) {
    return permissions?.sort((a, b) => {
      const priority = { NEW: 1, UPDATED: 2, default: 3 };

      const statusA = priority[a.status] || priority.default;
      const statusB = priority[b.status] || priority.default;

      return statusA - statusB;
    });
  }

  return (
    <div className="two-column-container">
      {sortPermissions(permissions)?.map((permission) => (
        <div className="permission_input">
          <PermissionInput
            textAreaClassName="textarea-small-medium no-resize-textarea"
            permission={permission}
            disabled={true}
          />
        </div>
      ))}
    </div>
  );
}

export default PermissionList;
