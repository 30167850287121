import { createTheme } from "@mui/material";

export const PaginationTheme = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        text: {
          fontSize: "var(--base-font-size)",
        },
        icon: {
          fontSize: "var(--base-font-size)",
        },
      },
    },
  },
});

export const ToolbarTheme = {
  "*": {
    fontSize: "var(--base-font-size)!important",
    fontFamily: "var(--base-font-family)!important",
  },
  "& * input[type=text]": {
    marginTop: "0",
  },
  "&  .MuiDataGrid-filterForm div": {
    width: "fit-content!important",
  },
  "&  .MuiDataGrid-toolbarQuickFilter input": {
    fontWeight: "var(--base-font-weight)",
  },
  "& .MuiDataGrid-filterForm div input[type=date]": {
    cursor: "pointer",
  },
  "&  .MuiFormControl-root .MuiSvgIcon-root": {
    color: "var(--primary-medium)",
  },
  "&  .MuiDataGrid-filterForm .MuiFormControl-root": {
    marginRight: "2vw",
  },
  "&  .MuiDataGrid-filterForm .MuiDataGrid-filterFormDeleteIcon": {
    marginRight: "0.5vw",
  },
  "&  .MuiDataGrid-filterForm .MuiDataGrid-filterFormValueInput": {
    marginRight: "0",
  },
  "&  .MuiDataGrid-filterForm label": {
    color: "var(--grey-medium-400)",
  },
  "& .MuiInput-root::after": {
    borderColor: "var(--primary-medium)!important",
  },
  "& .MuiInput-root::before": {
    borderColor: "var(--primary-focus-transparent)!important",
  },
  "& .MuiInput-root::before": {
    borderColor: "var(--primary-focus-transparent)!important",
  },
};

export const DataGridTheme = (props) => {
  return {
    border: "none",
    "& div": {
      fontSize: "var(--base-font-size)",
      fontFamily: "var(--base-font-family)",
    },
    "& .MuiDataGrid-toolbarContainer": {
      marginBottom: "1vh",
    },
    "& .MuiDataGrid-cell:focus , .MuiDataGrid-withBorderColor:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-overlayWrapper svg": {
      height: "30vh",
    },
    "& .MuiDataGrid-overlayWrapper": {
      height: "20vh",
      widows: "20vh",
    },
    "& .MuiSvgIcon-fontSizeSmall, .MuiButton-textSizeSmall": {
      fontSize: "var(--base-font-size)",
      fontFamily: "var(--base-font-family)",
      color: "var(--primary-medium)",
    },
    "& .MuiSvgIcon-fontSizeSmall, .MuiButton-textSizeSmall:hover": {
      backgroundColor: "white",
    },
    "& .MuiTablePagination-displayedRows": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "var(--font-weight-bold)",
    },
    "& .css-tvwwba-MuiDataGrid-root ": {
      height: "6vh",
    },
    "& .MuiDataGrid-container--top [role=row] ": {
      height: "6vh",
    },
    "& .MuiBadge-badge": {
      color: "var(--warning-medium)",
      backgroundColor: "var(--warning-medium)",
      height: "1.3vh",
      minWidth: "1.3vh",
      fontSize: "0",
    },
    "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell":
      {
        overflow: "hidden",
        minHeight: "6vh",
      },
    "& .MuiDataGrid-cell": {
      border: "none",
    },
    "& .MuiDataGrid-cell p": {
      overflowWrap: "anywhere",
    },
    "& .MuiDataGrid-columnHeaderRow MuiDataGrid-withBorderColor, .MuiDataGrid-withBorderColor":
      {
        border: "none",
        height: "inherit !important",
      },
    "& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderDraggableContainer":
      {
        height: "fit-content",
      },
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "var(--base-border)",
      borderColor: "var(--grey-light-150)",
      boxShadow: "0px 1px 0px 0px #D9D9D9",
      height: "6vh!important",
      minHeight: "3vh!important",
    },
    "& .MuiDataGrid-row": {
      fontWeight: "var(--base-font-weight)",
      borderRadius: "var(--base-border-radius)",
      backgroundColor: "var(--grey-light-028)",
      borderBottom: "var(--base-border)",
      boxShadow: "0px 1px 0px 0px #F5F5F5",
      borderColor: "#F5F5F5",
      paddingTop: "2vh",
      paddingBottom: "2vh",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDataGrid-row i": {
      color: "var(--grey-dark-800)",
    },
    "& .MuiDataGrid-row:hover, .Mui-hovered:after": {
      cursor: !props.isNavigationDisabled && "pointer",
    },
    "& .Mui-hovered:after": {
      backgroundColor: "white",
    },
    "& .MuiDataGrid-columnHeader--moving": {
      backgroundColor: "white",
    },
    "& .MuiDataGrid-columnSeparator": {
      color: "white",
    },
    "& .MuiDataGrid-filterIcon": {
      color: "var(--warning-medium)",
    },
    "& .MuiInput-root::after": {
      borderColor: "var(--primary-medium)!important",
    },
    "& .MuiInput-root::before": {
      borderColor: "var(--primary-focus-transparent)!important",
    },
    "& .MuiDataGrid-footerContainer": {
      justifyContent: "center",
      border: "none",
      margin: 0,
    },
    "& .MuiTablePagination-root div, .MuiTablePagination-root nav, .MuiTablePagination-root svg":
      {
        fontSize: "var(--base-font-size)",
        fontFamily: "var(--base-font-family)",
        padding: 0,
        margin: 0,
      },
    "& .MuiTablePagination-root svg": {
      width: "2.5vh",
      height: "2.5vh",
    },
    "& .MuiPaginationItem-root:hover, .MuiPaginationItem-root": {
      backgroundColor: "white",
      color: "var(--grey-light-150)",
      fontFamily: "var(--base-font-family)",
      fontSize: "var(--base-font-size)",
    },
    "& .MuiPaginationItem-root.Mui-selected, .MuiPaginationItem-root.Mui-selected:hover":
      {
        color: "var(--grey-dark-800)",
        backgroundColor: "white",
      },
    "& .MuiPaginationItem-root:hover": {
      color: "var(--grey-medium-600)",
      backgroundColor: "white",
    },
    "& .MuiPaginationItem-page:active": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
    "&.MuiDataGrid-scrollbar--vertical": {
      top: "none",
    },
    "& .MuiDataGrid-scrollbarFiller": {
      borderTop: "none!important",
    },
    "& .MuiDataGrid-main": {
      overflow: "visible",
      position: "initial",
    },
    "& .MuiDataGrid-filler": {
      display: "none",
    },
  };
};
