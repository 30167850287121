import React, { useContext, useState } from "react";
import {
  CcvButton,
  CcvHeading,
  CcvMaterialIcon,
} from "@ccv-oc-myccv/ccv-react-components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../style/Dialog.scss";
import "../../style/Labels.scss";
import { ReleaseService } from "../../services/ReleaseService";
import { AppContext } from "../../config/AppContext";
import FunctionalTestingBody from "./DialogBodies/infoBodies/FunctionalTestingBody";
import SecurityScanBody from "./DialogBodies/infoBodies/SecurityScanBody";
import SigningBody from "./DialogBodies/infoBodies/SigningBody";
import SpinnerService from "../../services/SpinnerService";

function InfoDialog({
  open,
  onDisagree,
  dialogTitle,
  dialogSubtitle,
  dialogInfo,
}) {
  const [t] = useTranslation();
  const { authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);

  function getDialogBodyByStatus(releaseStatus) {
    switch (releaseStatus) {
      case "FUNCTIONAL_TESTING_SKIPPED":
      case "FUNCTIONAL_TESTING_FAILED":
      case "FUNCTIONAL_TESTING_SUCCESS":
        return (
          <FunctionalTestingBody
            dialogInfo={dialogInfo}
            dialogSubtitle={dialogSubtitle}
            translate={t}
            downloadFromSharepoint={downloadFromSharepoint}
          />
        );
      case "SECURITYSCAN_FAILED":
      case "SECURITYSCAN_SUCCESS":
        return (
          <SecurityScanBody
            dialogInfo={dialogInfo}
            dialogSubtitle={dialogSubtitle}
            translate={t}
            downloadFromSharepoint={downloadFromSharepoint}
          />
        );
      case "SIGNING_FAILED":
        return (
          <SigningBody
            dialogInfo={dialogInfo}
            dialogSubtitle={dialogSubtitle}
            translate={t}
            downloadFromSharepoint={downloadFromSharepoint}
          />
        );
    }
  }

  function downloadFromSharepoint(fileType) {
    SpinnerService.defaultSpinner(
      releaseService
        .getDownloadLink(dialogInfo.releaseId, fileType)
        .then((response) => (window.location = response.data.downloadUrl)),
      t,
      "TOAST_DOWNLOADING",
      "TOAST_DOWNLOAD_SUCCEEDED",
      null
    );
  }

  return (
    <Dialog open={open} onClose={onDisagree} className="dialog">
      <DialogTitle>
        <span className="spacing-bottom">
          <CcvHeading
            size="small"
            text={t(dialogTitle)}
            testId={dialogTitle?.toLowerCase()}
          />
        </span>
        <Icon onClick={onDisagree}>
          <CcvMaterialIcon iconName="clear" size="large" />
        </Icon>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogInfo && getDialogBodyByStatus(dialogInfo.releaseStatus)}
        </DialogContentText>
      </DialogContent>
      <DialogActions data-testid="button-block-info">
        <CcvButton
          size="normal"
          type="primary"
          text="Ok"
          onClick={onDisagree}
        />
      </DialogActions>
    </Dialog>
  );
}

export default InfoDialog;
