import React from "react";
import { FunctionalTestingFeedback } from "../../../../domain/FunctionalTestingFeedback";
import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import FormatService from "../../../../services/FormatService";
import { ShowForRole } from "../../../ShowForRole";
import DownloadTypes from "../../../../domain/DownloadType";

function FunctionalTestingBody({
  downloadFromSharepoint,
  dialogInfo,
  dialogSubtitle,
  translate,
}) {
  function getFeedback() {
    if (dialogInfo.status === "FUNCTIONAL_TESTING_SKIPPED") {
      return translate(
        dialogInfo.functionalTest?.feedback
          ? FunctionalTestingFeedback[dialogInfo.functionalTest.feedback]
              .description
          : "NO_FEEDBACK_TEXT"
      );
    }

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: FormatService.getHTMLFormat(
            dialogInfo.functionalTest?.feedback
          ),
        }}
      />
    );
  }

  return (
    <div className="feedback-container">
      <div className="feedback-heading">
        <CcvText
          size="small"
          color="dark-grey"
          textStyle="bold"
          testId={dialogSubtitle.toLowerCase()}
        >
          {translate(dialogSubtitle)}
        </CcvText>
        <CcvText size="small" color="medium-grey">
          {dialogInfo.date} {dialogInfo.time}
        </CcvText>
      </div>
      {dialogInfo.functionalTest?.overruler != "NOT_OVERRULED" && (
        <ShowForRole permission="Employee">
          <CcvText
            size="medium"
            color="dark-grey"
            testId="feedback_container"
            textStyle="semi-bold"
          >
            {translate("OVERRULER_LABEL")}:{" "}
            {dialogInfo.functionalTest?.overruler}
          </CcvText>
        </ShowForRole>
      )}
      <CcvText
        size="medium"
        color="dark-grey"
        testId={FunctionalTestingFeedback[
          dialogInfo.functionalTest?.feedback
        ]?.description?.toLowerCase()}
      >
        {!dialogInfo.functionalTest?.feedback
          ? translate("NO_FEEDBACK_TEXT")
          : getFeedback()}
      </CcvText>
      {dialogInfo.functionalTest?.reportFileAvailable && (
        <div
          className="report-download"
          data-testid="download_report"
          onClick={() =>
            downloadFromSharepoint(DownloadTypes.FUNCTIONAL_TEST_REPORT)
          }
        >
          <span className="report-download-tekst">
            <CcvText size="medium" color="dark-grey">
              <CcvMaterialIcon
                iconName="description"
                size="medium"
                iconStyle="outlined"
              />
              {translate("FUNCTIONAL_TESTING_REPORT")}
            </CcvText>
          </span>
          <CcvMaterialIcon
            iconName="file_download"
            size="medium"
            iconStyle="outlined"
          />
        </div>
      )}
    </div>
  );
}

export default FunctionalTestingBody;
