import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";
import FormatService from "../../../../services/FormatService";
import SpinnerService from "../../../../services/SpinnerService";
import DownloadTypes from "../../../../domain/DownloadType";

function SigningBody({
  dialogInfo,
  dialogSubtitle,
  translate,
  downloadFromSharepoint,
}) {
  return (
    <div className="feedback-container">
      <div className="feedback-heading">
        <CcvText
          size="small"
          color="dark-grey"
          textStyle="bold"
          testId={dialogSubtitle.toLowerCase()}
        >
          {translate(dialogSubtitle)}
        </CcvText>
        <CcvText size="small" color="medium-grey">
          {dialogInfo.date} {dialogInfo.time}
        </CcvText>
      </div>
      <CcvText size="medium" color="dark-grey" testId="feedback_container">
        {dialogInfo.signing?.feedback && (
          <div
            dangerouslySetInnerHTML={{
              __html: FormatService.getHTMLFormat(dialogInfo.signing.feedback),
            }}
          />
        )}
      </CcvText>
      {dialogInfo.signing?.reportFileAvailable && (
        <div
          className="report-download"
          data-testid="download_signing_report"
          onClick={() => downloadFromSharepoint(DownloadTypes.SIGNED_APK)}
        >
          <span className="report-download-tekst">
            <CcvText size="medium" color="dark-grey">
              <CcvMaterialIcon
                iconName="description"
                size="medium"
                iconStyle="outlined"
              />
              {translate("SIGNING_REPORT_LABEL")}
            </CcvText>
          </span>
          <CcvMaterialIcon
            iconName="file_download"
            size="medium"
            iconStyle="outlined"
          />
        </div>
      )}
    </div>
  );
}

export default SigningBody;
