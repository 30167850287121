import { CcvHeading, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import EmptyIconBlock from "../../../../components/emptyBlocks/EmptyIconBlock";
import RowTable from "../../../../components/tables/rowTable/RowTable";
import Textarea from "../../../../components/form/Textarea";
import FormValidation from "../../../../domain/FormValidation";
import DownloadTypes from "../../../../domain/DownloadType";
import LoadingBlock from "../../../../components/Loading/LoadingBlock";
import ImageCarousel from "../../../../components/common/ScreenshotCarousel";

function ApplicationInfoComponent({
  release,
  ccvStoreInfo,
  waitingForStoreInfoResponse,
  downloadFromSharepoint,
  downloadImagesFromSharepoint,
}) {
  const [t] = useTranslation();
  const [releaseNotes, setReleaseNotes] = useState("");

  useEffect(() => {
    if (release) {
      setReleaseNotes(release.releaseNotes);
    }
  }, [release]);

  function getLoadingOrEmptyBody() {
    if (waitingForStoreInfoResponse) {
      return <LoadingBlock />;
    } else {
      return (
        <EmptyIconBlock emptyText="APPLICATION_INFO_EMPTY" translate={t} />
      );
    }
  }

  function getReleaseNotes() {
    return (
      release && (
        <form>
          <div className="release-notes-block-app-info">
            <CcvText size="medium" color="dark-grey">
              {t("RELEASE_NOTES_LABEL")}
            </CcvText>{" "}
          </div>
          <Textarea
            translate={t}
            field={"releaseNotes"}
            defaultInputData={releaseNotes}
            disabled={true}
            testId={"releasenotes"}
            className="textarea-medium"
            classNameBlock={"no-spacing"}
            maxLength={FormValidation.MAX_RELEASENOTES_LENGTH}
          />
        </form>
      )
    );
  }

  return (
    <>
      <span className="spacing-bottom">
        <CcvHeading
          text={t("APPLICATION_INFO_HEADING")}
          size="small"
          testId="application_info_title"
        />
      </span>
      {ccvStoreInfo && !waitingForStoreInfoResponse ? (
        <div className="multi-side-block">
          <div className="left">
            <RowTable
              translate={t}
              data={[
                {
                  columnName: "BASICTABLE_SIGNED_APK",
                  data: release?.signing?.signedApk?.sha256
                    ? "DOWNLOAD_SIGNED_LABEL"
                    : "EMPTY_SIGNED_APK",
                  function: () =>
                    downloadFromSharepoint(DownloadTypes.SIGNED_APK),
                  type: release?.signing?.signedApk?.sha256
                    ? "download"
                    : "unavailable",
                },
                {
                  columnName: "APPLICATION_NAME_LABEL",
                  data: ccvStoreInfo.applicationName,
                },
                {
                  columnName: "APP_ICON_LABEL",
                  data: "DOWNLOAD_APPICON_TEXT",
                  function: () =>
                    downloadImagesFromSharepoint(
                      "APPICON",
                      ccvStoreInfo.appIcon
                    ),
                  image: (
                    <img
                      data-testid="appIcon"
                      src={ccvStoreInfo.appIcon[0]?.preview}
                      alt="app icon"
                      className="accepted-app-icon image-spacing"
                    />
                  ),
                  type: "downloadAndImage",
                },
                {
                  columnName: "SCREENSHOTS_LABEL",
                  data: "DOWNLOAD_SCREENSHOTS_LABEL",
                  function: () =>
                    downloadImagesFromSharepoint(
                      "SCREENSHOTS",
                      ccvStoreInfo.screenshots
                    ),
                  image: (
                    <div className="screenshots-detail">
                      <ImageCarousel
                        images={ccvStoreInfo.screenshots}
                        testId="screenshots"
                        altText="app screenshots "
                      />
                    </div>
                  ),
                  type: "downloadAndImage",
                },
              ]}
            />
          </div>
          <div className="right">
            {getReleaseNotes()}
            <Textarea
              translate={t}
              labelText={"SHORT_DESCRIPTION_LABEL"}
              defaultInputData={ccvStoreInfo.shortDescription}
              disabled={true}
              className={"textarea-extra-small"}
              testId={"short_description"}
            />
            <Textarea
              translate={t}
              labelText={"DESCRIPTION_LABEL"}
              defaultInputData={ccvStoreInfo.description}
              disabled={true}
              className={"textarea-medium"}
              testId={"description"}
            />
          </div>
        </div>
      ) : (
        getLoadingOrEmptyBody()
      )}
    </>
  );
}

export default ApplicationInfoComponent;
