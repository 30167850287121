import { AppContext } from "../../config/AppContext";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Role from "../../domain/Role";

function PostRenew({ component: Component, ...rest }) {
  const { authService } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    authService
      ?.handlePostRenew()
      .then((response) => {
        authService
          .getRoles()
          .then((roles) =>
            authService.isEmployee(roles)
              ? navigate("/actions")
              : navigate("/home")
          );
      })
      .catch((error) => {
        console.log("Error postrenew", error);
      });
  });

  return <React.Fragment />;
}
export default PostRenew;
