import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../config/AppContext";
import SmartTable from "../../components/tables/Smarttable/SmartTable";
import emptyStateImage from "../../assets/images/empty-actions.svg";
import "../../style/OverviewActions.scss";
import "../../style/Table.scss";
import { CcvButton, CcvHeading } from "@ccv-oc-myccv/ccv-react-components";
import SpinnerService from "../../services/SpinnerService";
import EmptyImageBlock from "../../components/emptyBlocks/EmptyImageBlock";
import { ReleaseService } from "../../services/ReleaseService";
import ReleaseStatus from "../../domain/ReleaseStatus";
import { trackPromise } from "react-promise-tracker";
import FormatService from "../../services/FormatService";

/**
   @todo: de hardgecodeerde rollen moeten veranderen
**/

function OverviewActions(props) {
  const { setTitle, authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);
  const [projectsWithActions, setProjectsWithActions] = useState({
    CREATED: [
      {
        id: 1,
        lastUpdated: null,
        version: null,
        projectName: null,
        lastEditedBy: null,
      },
    ],
    SECURITYSCAN_SUCCESS: [
      {
        id: 1,
        lastUpdated: null,
        version: null,
        projectName: null,
        lastEditedBy: null,
      },
    ],
    SIGNING_SUCCESS: [
      {
        id: 1,
        lastUpdated: null,
        version: null,
        projectName: null,
        lastEditedBy: null,
      },
    ],
  });
  const [assignedTasks, setAssignedTasks] = useState([
    {
      id: 1,
      nextAction: null,
      lastUpdated: null,
      version: null,
      projectName: null,
    },
  ]);
  const [t] = useTranslation();
  const roles = ["STORE_ADMIN", "SIGNER", "TESTER", "PROJECT_ENGINEER"];
  const tableOrder = [
    "FUNCTIONAL_TESTING",
    "SECURITYSCAN",
    "SIGNING",
    "UPLOAD_TO_STORE",
  ];

  async function fetchProjectsWithActions() {
    return trackPromise(
      releaseService
        .getProjectsWithActions(roles)
        .then((response) => {
          setProjectsWithActions(response.data);
        })
        .catch((error) => {
          setProjectsWithActions({
            FUNCTIONAL_TESTING: [],
            SECURITYSCAN: [],
            SIGNING: [],
            UPLOAD_TO_STORE: [],
          });
          throw error;
        }),
      "actions"
    );
  }

  async function fetchAssignedTasks() {
    return trackPromise(
      releaseService
        .getAssignedReleases(authService.getEmail())
        .then((response) => {
          setAssignedTaksResponse(response.data);
        })
        .catch((error) => {
          setAssignedTasks(null);
          throw error;
        }),
      "assigned-tasks"
    );
  }

  function setAssignedTaksResponse(data) {
    if (data.length === 0) {
      setAssignedTasks(null);
    }
    setAssignedTasks(
      data.map((releaseResponse) => {
        return {
          id: releaseResponse.id,
          nextAction: t(
            ReleaseStatus[releaseResponse.releaseStatus].nextActionTitle
          ),
          lastUpdated: releaseResponse.lastUpdated,
          bupaName: releaseResponse.project.bupaName,
          projectName: releaseResponse.project.projectName,
          version: releaseResponse.version,
          lastEditedBy: releaseResponse.lastEditedBy,
        };
      })
    );
  }

  function getEmptyBlock(tableName, emptyMessage, classname) {
    return (
      <div className={"info-block " + classname}>
        <CcvHeading
          text={t("SMARTTABLE_" + tableName + "_TITLE")}
          size="small"
          testId={tableName.toLowerCase() + "_table_title"}
        />
        <EmptyImageBlock
          image={emptyStateImage}
          emptyText={emptyMessage}
          translate={t}
        />
      </div>
    );
  }

  function getTablesForActions() {
    return tableOrder.map((tableName) =>
      projectsWithActions[tableName]?.length > 0 ? (
        <div className="info-block" data-testid={tableName?.toLowerCase()}>
          <SmartTable
            data={projectsWithActions[tableName]}
            promiseArea={"actions"}
            title={"SMARTTABLE_" + tableName + "_TITLE"}
            pageSize={5}
            filterFields={[
              "lastUpdated",
              "releaseName",
              "projectName",
              "bupaName",
              "version",
              "lastEditedBy",
              tableName === "SECURITYSCAN" && "assignedTo",
            ]}
            actions={{
              button: (
                <CcvButton
                  size="small"
                  type="secondary"
                  text="Release detail"
                  testId="release_detail_button"
                />
              ),
              redirect: "/release/detail/",
            }}
            isFilterDisabled={true}
          />
        </div>
      ) : (
        getEmptyBlock(tableName, "SMARTTABLE_INFO_TEXT_NO_ACTIONS")
      )
    );
  }

  function fetchActionsAndAssignedTasks() {
    SpinnerService.errorSpinner(
      Promise.all([fetchProjectsWithActions(), fetchAssignedTasks()]),
      t
    );
  }

  useEffect(() => {
    setTitle(props.title);
    fetchActionsAndAssignedTasks();
  }, []);

  return (
    <>
      <div className="table-block">
        {assignedTasks?.length > 0 ? (
          <div className="info-block assigned-tasks-table">
            <SmartTable
              data={assignedTasks}
              promiseArea={"assigned-tasks"}
              title={"SMARTTABLE_ASSIGNED_TASKS_TITLE"}
              filterFields={[
                "nextAction",
                "lastUpdated",
                "projectName",
                "bupaName",
                "version",
                "lastEditedBy",
              ]}
              actions={{
                button: (
                  <CcvButton
                    size="small"
                    type="secondary"
                    text="Release detail"
                    testId="release_detail_button"
                  />
                ),
                redirect: "/release/detail/",
                tooltip: "release detail",
              }}
            />
          </div>
        ) : (
          getEmptyBlock(
            "ASSIGNED_TASKS",
            "NO_ASSIGNED_TASKS_LABEL",
            "assigned-tasks-table"
          )
        )}
      </div>

      <div className="table-block">{getTablesForActions()}</div>
    </>
  );
}

export default OverviewActions;
