import React, { useContext, useEffect, useState } from "react";
import PermissionList from "../../../components/common/PermissionList";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../config/AppContext";
import {
  CcvHeading,
  CcvMaterialIcon,
  CcvMessage,
} from "@ccv-oc-myccv/ccv-react-components";
import { ReleaseService } from "../../../services/ReleaseService";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import SpinnerService from "../../../services/SpinnerService";
import { Spinner } from "../../../components/Loading/Spinner";

function PermissionListComponent(props) {
  const { setTitle, authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);
  const releaseId = useParams().id;
  const { t } = useTranslation();
  const location = useLocation();
  const [permissions, setPermissions] = useState();
  const { promiseInProgress } = usePromiseTracker({
    area: "permissions",
    delay: 0,
  });

  useEffect(() => {
    setTitle(t(props.title));
    if (location?.state?.data) {
      setPermissions(location?.state?.data);
    } else {
      trackPromise(
        SpinnerService.errorSpinner(fetchReleaseData(), t),
        "permissions"
      );
    }
  }, []);

  async function fetchReleaseData() {
    await authService.ensureAuthentication();
    return releaseService
      .getReleaseByIdAndBupaId(releaseId, authService.getBupaId())
      .then((response) =>
        setPermissions(response.data.project.apkInfo.permissions)
      );
  }

  return (
    <div className="info-block-full-page">
      <CcvHeading
        text={permissions?.length + " " + t("PERMISSIONS_INFO_HEADING")}
        size="medium"
        testId="PERMISSIONS_HEADING"
      />

      <Spinner area={"permissions"} dataIsFetched={permissions}>
        <div className="message-block message-block-permissions spacing-top spacing-bottom">
          <CcvMessage
            text={
              <span className="message-block-text">
                <CcvMaterialIcon
                  iconName={!permissions?.length ? "check" : "error"}
                  size="medium"
                />
                {!permissions?.length
                  ? t("NO_DANGEROUS_OR_UNKNOWN_PERMISSIONS_MESSAGE")
                  : t("DANGEROUS_OR_UNKNOWN_PERMISSIONS_MESSAGE")}
              </span>
            }
            type={!permissions?.length ? "success" : "alert"}
            testId={
              !permissions?.length
                ? "no_dangerous_or_unknown_permissions_message"
                : "dangerous_or_unknown_permissions_message"
            }
          />
        </div>
        <PermissionList permissions={permissions} />
      </Spinner>
    </div>
  );
}

export default PermissionListComponent;
