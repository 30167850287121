const FormValidation = {
  //files
  MAX_REPORT_FILE_SIZE: 25165824,
  MAX_APK_FILE_SIZE: 200000000,
  MIN_FILE_SIZE: 1,
  MAX_ICON_SIZE: 2097152,
  MAX_SCREENSHOTS_SIZE: 25000000,

  //input
  //MAX
  MAX_RELEASENOTES_LENGTH: 5000,
  MAX_URL_INPUT: 255,
  MAX_PROJECT_NAME_LENGTH: 64,
  MAX_FEEDBACK_LENGTH_LARGE: 2500,
  MAX_FEEDBACK_LENGTH_SMALL: 250,
  MAX_LOG4JVERSION_LENGTH: 20,
  MAX_APK_OWNER_LENGTH: 70,
  MAX_APK_OWNER_POSITION_LENGTH: 70,
  MAX_APPLICATION_NAME_LENGTH: 64,
  MAX_SHORT_DESCRIPTION_LENGTH: 255,
  MAX_DESCRIPTION_LENGTH: 5000,
  MAX_SCREENSHOTS_LENGTH: 5,
  MAX_MAPI_LENGTH: 14,
  MAX_ENGINE_VERSION_LENGTH: 20,
  MAX_FLOW_DESCRIPTION_LENGTH: 5000,
  MAX_SIGNER_NAME_LENGTH: 64,
  MAX_OVERRULER_NAME_LENGTH: 30,
  MAX_REASON_INPUT_LENGTH: 750,
  //MIN
  MIN_SCREENSHOTS_LENGTH: 3,
};

export default FormValidation;
